import React, { useState, useContext, useEffect } from 'react';
import JSONHelper from '../../helpers/JSON';
import QuizContext from '../../context/quiz/quizContext';

import Image from './Image';


const QuestionOption = ({ accordion: accordion, callback, no: answerIdent, type: questionType, json, contentJson, waypointJson, groupJson, minimumAnswers }) => {

    const quizContext = useContext(QuizContext);

    const isCorrect = JSONHelper.getValue(json, 'correct', false) === 'yes';
    const isAccorion = accordion ? accordion : false;

    const groupIdent = groupJson.admin.id;
    const waypointIdent = waypointJson.admin.id;
    const contentIdent = contentJson.admin.id;

    const [clickEvent, setClickEvent] = useState(false);

    var clicked = false;
    if (quizContext.answers[groupIdent] && quizContext.answers[groupIdent][waypointIdent] && quizContext.answers[groupIdent][waypointIdent][contentIdent]) {
        if (quizContext.answers[groupIdent][waypointIdent][contentIdent][answerIdent]) {
            clicked = true;
        }
    }


    useEffect(() => {

    }, []);


    // @a11yButtonActionHandler
    const selectQuestion = (event) => {
        if (event.type === 'click' || event.key === 'Enter' || event.key === ' ') {
            if (!isAccorion) {
                if (!clicked) {
                    callback(answerIdent, isCorrect, true);
                } else {
                    callback(answerIdent, isCorrect, false);
                }

                setClickEvent(true);

                setTimeout(() => {
                    setClickEvent(false);
                }, 400);

            }
        }
    };

    const renderSwitch = (type) => {
        //console.log(json);
        switch (type) {
            case 'text':
                const text = JSONHelper.getSingleTextValue(json, 'option', false);
                return (
                    text
                );
            case 'images':
                const questionImage = JSONHelper.getValue(json, 'questionImage', {});
                const multimedia = JSONHelper.getValue(questionImage, 'multimedia', false);
                var imgData = false;
                if (multimedia && multimedia[0]) {
                    imgData = multimedia[0];
                }

                return (
                    imgData ? <Image json={imgData} size="medium" /> : ''
                );
            default:
                return '';
        }
    };

    const answerClass = (type) => {
        var classes = [];
        if (clicked) {
            classes.push('selected');
        }

        if (clickEvent) {
            classes.push('clicked');
        }

        if (isAccorion) {
            if (isCorrect && clicked) {
                classes.push('correct');
            }

            if (isCorrect && !clicked) {
                classes.push('missed');
            }

            if (!isCorrect && clicked) {
                classes.push('wrong');
            }
        }

        return classes.join(' ');
    }

    return (
        <li className={answerClass()} onKeyDown={selectQuestion} onClick={selectQuestion} tabIndex={(isAccorion ? '-1' : '0')}>
            {renderSwitch(questionType)}
        </li>
    )
};
export default QuestionOption;
