import React from 'react';
import { NavLink } from 'react-router-dom';
import Textnl2p from './Textnl2p';

import BackgroundImage from './BackgroundImage';

import AppHelper from '../../helpers/App';
import JSONHelper from '../../helpers/JSON';

const WaypointItem = ({ no, json, group: groupJson, site: siteJson }) => {

    const waypointId = json.admin.id;
    const waypointData = AppHelper.getWaypointDataFromGroup(waypointId, groupJson);

    const url = '/' + waypointData.waypointUrl;;

    //console.log(waypointData);

    //console.log(json);
    //console.log(groupJson);

    return (
        <li>
            <NavLink to={url}>

                <BackgroundImage json={JSONHelper.getValue(waypointData, 'multimedia', false)} />

                <div>
                    <em>{no}</em>
                    <strong><span>{JSONHelper.getSingleTextValue(json, 'title')}</span></strong>
                    <Textnl2p text={JSONHelper.getSingleTextValue(waypointData, 'listSummaryText')} />
                </div>
            </NavLink>
        </li>
    )
};
export default WaypointItem;
