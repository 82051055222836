import React, { useState, useRef, useContext, useEffect } from 'react';

import AppContext from '../context/app/appContext';
import AppHelper from '../helpers/App';
import JSONHelper from '../helpers/JSON';

import Layout from '../layout/WithHeader';
import Head from './components/Head';
import BottomLinks from './components/BottomLinks';
import Share from './components/Share';
import TrailItem from './components/TrailItem';
import SearchLink from './components/SearchLink';
import ReadMore from './components/ReadMore';
import FullwidthImage from './components/FullwidthImage';

/*
if (process.env.NODE_ENV !== 'production') {
    var ReactDOM = require('react-dom');
    var axe = require('react-axe');
    axe(React, ReactDOM, 1000);
}
*/

const Site = ({ location, history }) => {


    const appContext = useContext(AppContext);

    const url = AppHelper.getUrlFromLocation(location);
    const json = AppHelper.getSiteByUrl(appContext, url);
    const groupsIds = AppHelper.getAdminIdsFromRefs(json, 'groups', 'group');
    const groups = AppHelper.getContentsByAdminIds(appContext, groupsIds, 'groups');
    const siteId = json.admin.id;

    // get suitability options
    // todo get if as vocab
    var suitabilityOptions = {};
    groups.forEach((g, index) => {
        if (g.suitability) {
            g.suitability.forEach((s, index) => {
                suitabilityOptions[s] = s;
            });
        } else {
            g.suitability = [];
        }
    });


    const suitabilityOptionsKeys = Object.keys(suitabilityOptions);
    const categoryIdent = appContext.getCategory(siteId);

    useEffect(() => {
        if (categoryIdent !== false) {
            localStorage.setItem('disable_scroll', "1");
            showListByIdent(categoryIdent);
        }
    }, [categoryIdent]);


    const [showCategories, setShowCategories] = useState(true);
    const [hasCategories, setHasCategories] = useState(suitabilityOptionsKeys.length > 1);
    const [categoryName, setCategoryName] = useState('');
    //const [categoryIdent, selectCategory] = useState('*');

    const myRef = useRef(null)
    const scrollToRef = (ref) => {
        const yOffset = -80;
        const y = ref.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
        setTimeout(() => {
            window.scrollTo({ top: y, behavior: 'smooth' });
        }, 300);

    };

    const showList = (ident, event) => {

        if (event.type === 'click' || event.key === 'Enter' || event.key === ' ') {
            appContext.selectCategory(siteId, ident);
            scrollToRef(myRef);
        }
        event.preventDefault();
        return false;
    }

    const showListByIdent = (ident) => {

        if (ident === '*') { // all
            setCategoryName('');
        } else {
            setCategoryName(' for ' + suitabilityOptions[ident]);
        }
        setShowCategories(false)

    }

    const hideList = (event) => {
        if (event.type === 'click' || event.key === 'Enter' || event.key === ' ') {
            setShowCategories(true)
            appContext.selectCategory(siteId, false);
            scrollToRef(myRef);
        }
        event.preventDefault();
        return false;
    }


    return (
        <Layout>

            {showCategories && <Head history={history} />}
            {!showCategories && <Head closeCallback={hideList} history={history} />}

            <main className={`location ${(json.sort & 1) ? "odd" : "even"}`} role="main" aria-labelledby="headline">
                <article>
                    <strong className="headline">Mobile Guide</strong>
                    <h1 id="headline">{JSONHelper.getSingleTextValue(json, 'title')}</h1>

                    <FullwidthImage json={JSONHelper.getValue(json, 'multimedia', false)} />

                    <ReadMore text={JSONHelper.getSingleTextValue(json, 'introduction')} onExpand={() => { }} onCollapse={() => { }} />

                </article>

                <Share homepage={true} />

                <div ref={myRef}>

                    <article className={`categories ${(showCategories && hasCategories) ? 'visible' : ''}`}>
                        <h2>What kind of content are you looking for</h2>
                        <ul className="arrow-list">

                            {suitabilityOptionsKeys.map((ident, s) => {
                                return (
                                    <li key={ident}>
                                        <a href="#" onKeyDown={(event) => showList(ident, event)} onClick={(event) => showList(ident, event)}><strong>{suitabilityOptions[ident]}</strong></a>
                                    </li>
                                )
                            })}

                            <li>
                                <a href="#" onKeyDown={(event) => showList('*', event)} onClick={(event) => showList('*', event)}><strong>All Content</strong></a>
                            </li>
                        </ul>
                    </article>


                    <article className={`trails-list-wrap ${(!showCategories || !hasCategories) ? 'visible' : ''}`}>
                        <h2>Choose a trail or a quiz</h2>

                        {groups &&
                            <ul className="trails-list">

                                {hasCategories && groups.filter(group => (categoryIdent === '*' || group.suitability.filter(s => (s === categoryIdent)).length)).map((group, i) => {
                                    return (<TrailItem key={i} no={i + 1} json={group} site={json} />)
                                })}

                                {!hasCategories && groups.map((group, i) => {
                                    return (<TrailItem key={i} no={i + 1} json={group} site={json} />)
                                })}

                            </ul>
                        }
                        {hasCategories &&
                            <ul className="links">
                                {categoryIdent !== '*' &&
                                    <li><a href="" onKeyDown={(event) => showList('*', event)} onClick={(event) => showList('*', event)}>Show all content</a></li>
                                }
                                <li><a href="" onKeyDown={(event) => hideList(event)} onClick={(event) => hideList(event)}>Select content type</a></li>
                            </ul>
                        }

                    </article>

                    {!showCategories && <SearchLink history={history} />}
                </div>

                <BottomLinks />

            </main>
        </Layout>
    )
}
export default Site;
