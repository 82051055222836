import React from 'react';

import Layout from '../layout/WithHeader';
import Head from './components/Head';
import SearchLink from './components/SearchLink';
import BottomLinks from './components/BottomLinks';

const NotFound = (props) => {

    return (
        <Layout>

            <Head history={props.history} />

            <main className="not-found" role="main" aria-labelledby="headline">
                <article>
                    <h1 id="headline">Not found</h1>
                    <strong className="headline">Sorry, we can’t find the page you were looking for.</strong>

                </article>

                <SearchLink history={props.history} />

                <BottomLinks />

            </main>
        </Layout>
    )
}
export default NotFound;
