
function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

const config = {
    mode: process.env.NODE_ENV,
    trackingId: "UA-116314451-4",
    isPreview: getParameterByName('previewmode') !== null,
    isSinglePreview: getParameterByName('singlepreviewmode') !== null,
    mediaURL: 'https://rafm.tours/ciim-media/',
    elasticSearchLive: 'https://rafm.tours/ciim-search',
    elasticSearchTest: 'http://rafs.code8.cz/public/api/mobile/search', // '',http://172.16.0.84/es-ext/_search
    zoomImageURL: 'https://rafm.tours/ciim-iip/iipsrv.fcgi?Deepzoom=/var/www/ciim-ecosystem/zooms/',
    jsonsUrl: '/json/',
    loginEndpoint: 'http://rafs.code8.cz/public/api/mobile/login',
    userData: {
        email: 'ondra@code8.cz',
        password: 'OndraFurier8!',
    },
    getMediaURL() {
        //console.log(process.env);
        return this.mediaURL
    },
    getZoomImageURL() {
        return this.zoomImageURL
    },
    getSearchURL() {
        return (this.isLive() ? this.elasticSearchLive : this.elasticSearchTest);
    },
    isLive() {
        return this.mode === 'production';
    },
    isPreviewMode() {
        return this.isPreview || this.isSinglePreview;
    },
    isSinglePreviewMode() {
        return this.isSinglePreview;
    }
};

module.exports = config;
