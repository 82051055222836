import React, { useEffect } from 'react';
import AppState from './context/app/AppState';
import AudioState from './context/audio/AudioState';
import QuizState from './context/quiz/QuizState';
import GalleryState from './context/gallery/GalleryState';
import ProgressState from './context/progress/ProgressState';

import AppWrapper from './AppWrapper';

import './App.scss';

// TODO
/*  
results ?
localstorage?
search

trail - This trail will take about 2 hours.
trail item -  / 2 hours
*/

function App() {

    var backTimeout;

    const scrolling = () => {
        if (window.scrollY > 0) {
            document.body.classList.add('scrolling');
            backTimeout = setTimeout(() => {
                document.body.classList.add('show-back');
            }, 500);
        } else {
            document.body.classList.remove('show-back');
            document.body.classList.remove('scrolling');
            clearTimeout(backTimeout);
        }
    }

    useEffect(() => {


        let ua = window.navigator.userAgent;
        let iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
        let webkit = !!ua.match(/WebKit/i);
        let iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
        if (iOSSafari) {
            document.body.classList.add('is-safari');
        }

        window.addEventListener('scroll', scrolling);

        return () => {
            window.removeEventListener('scroll', scrolling);
        }


    }, []);


    return (
        <AppState>
            <AudioState>
                <QuizState>
                    <GalleryState>
                        <ProgressState>
                            <AppWrapper />
                        </ProgressState>
                    </GalleryState>
                </QuizState>
            </AudioState>
        </AppState >
    );
}

export default (App);
