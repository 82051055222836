import React from 'react';

const Layout = (props) => {
    return (
        <div className="page">
            <div className="container" id="container">
                {props.children}
            </div>
        </div>
    )
}
export default Layout;
