import React, { useEffect,useState, useContext } from 'react';
import ReactGA from 'react-ga';
import { BrowserRouter, Switch, Route, withRouter } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import AppContext from './context/app/appContext';
import config from "./config";
import PageSwitch from './PageSwitch';

import Loading from './pages/Loading';
import ScrollToTop from './pages/components/ScrollToTop';
import Home from './pages/Home';
import About from './pages/About';

import Search from './pages/Search';
import AudioPlayer from './pages/components/contentTypes/AudioPlayer';
import TrackView from './pages/components/TrackView';



// test data
//import json from './data/sites_trails.json';

// jsons - temp imports, later we will fetch data
//import homepageJSON from './data/homepage.json';
//import sectionsJSON from './data/sections.json';

function AppWrapper() {

    const [gaInit, setgaInit] = useState(false);
    const appContext = useContext(AppContext);

    // Initialize google analytics page view tracking 
    if ( !gaInit ) {
        ReactGA.initialize(config.trackingId);
        setgaInit(true)
    }
    

    useEffect(() => {
        appContext.getSitesAndTrails()
        //appContext.getContentsInReview() 
    }, []);

    const isMovingRight = () => {
        let goingBack = localStorage.getItem("going_back") === '1';
        localStorage.setItem("going_back", '0');
        return goingBack;
    }

    const isFading = () => {
        return appContext.transition == 'fade';
    }

    const animationClass = () => {
        return 'fade';
        let className = '';
        if (isFading()) {
            className = 'fade';
        } else {
            className = isMovingRight() ? "slide-right" : "slide-left";
        }
        return className;
    }

    const Contents = withRouter(({ history, location, match }) => {
    
        return (
            <ScrollToTop location={location}>
                <TrackView location={location} ReactGA={ReactGA}>
                    <TransitionGroup>
                        <CSSTransition
                            key={location.key}
                            timeout={{ enter: 0, exit: 300 }}
                            classNames={'page-slide'}
                        >
                            <div
                                className={animationClass()}
                            >
                                <Switch location={location}>
                                    <Route exact path='/' component={Home} location={location} />
                                    <Route exact path='/search' component={Search} location={location} />
                                    <Route exact path='/about' component={About} location={location} />
                                    <Route component={PageSwitch} location={location} />
                                </Switch>
                            </div>
                        </CSSTransition>
                    </TransitionGroup>
                </TrackView>
            </ScrollToTop>);
    });

    return appContext.loaded ? (
        <div className="app-wrap">
            <BrowserRouter>
                <Contents />
            </BrowserRouter>

            <AudioPlayer />

        </div>
    ) : <Loading />;

}

export default (AppWrapper); 