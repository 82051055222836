import React, { useContext, useEffect } from 'react';

import Layout from '../layout/WithHeader';
import Head from './components/Head';
import WaypointItem from './components/WaypointItem';
import MapLink from './components/MapLink';

import AppContext from '../context/app/appContext';
import AppHelper from '../helpers/App';
import JSONHelper from '../helpers/JSON';


/*
if (process.env.NODE_ENV !== 'production') {
    var ReactDOM = require('react-dom');
    var axe = require('react-axe');
    axe(React, ReactDOM, 1000);
}
*/

const Waypoints = ({ location, history }) => {

    const appContext = useContext(AppContext);

    const url = AppHelper.getFirstUrlFromLocation(location);
    const groupJson = AppHelper.getGroupByUrl(appContext, url);
    const groupDataJson = AppHelper.getGroupDataByUrl(appContext, url);

    const groupId = groupJson.admin.id;
    const siteJson = AppHelper.getSiteByGroupUrl(appContext, url);
    const waypointsCount = groupJson.waypoints.length;
    const groupUrl = '/' + AppHelper.getGroupUrlById(appContext, groupId);
    //console.log('groupUrl',groupUrl)
    //const closeUrl = '/' + siteJson.siteUrl;

    // has map link? 
    var showMapLink = AppHelper.isMapLinkVisible(groupJson);


    // get waypoints 
    useEffect(() => {
        appContext.getWaypoints(groupJson)

        appContext.waypointsVisitedReset(groupJson.admin.id);
    }, []);

    return (
        <Layout>

            <Head closeUrl={groupUrl} headline={JSONHelper.getSingleTextValue(groupJson, 'title')} history={history} showProgess={true} groupJson={groupJson} waypointJson={false} waypointData={false} completed={false} />

            <main className={`sections ${(groupDataJson.sort & 1) ? "odd" : "even"} ${(showMapLink) ? "" : "no-map-link"}`} role="main" aria-labelledby="headline">
                <article>
                    <h1 id="headline">This trail has {waypointsCount} location{waypointsCount > 1 ? 's' : ''}. Where would you like to start?</h1>
                </article>

                {showMapLink && <MapLink history={history} url={url} groupId={groupId} />}
                {appContext.waypoints[groupId] &&
                    <ul className="sections-list">
                        {appContext.waypoints[groupId].map((waypoint, i) => {
                            return (<WaypointItem key={i} no={i + 1} json={waypoint} group={groupJson} site={siteJson} />)
                        })}
                    </ul>
                }

            </main>

        </Layout >
    )
}
export default Waypoints;
