export default class QuizHelper {


    static getAnsweredQuestionsCount = (quizContext) => {
        var total = 0;
        if (!quizContext.answers) {
            return total;
        }

        for (var k in quizContext.answers) {
            if (quizContext.answers.hasOwnProperty(k)) {
                total += this.getAnsweredQuestionsCountPerSection(quizContext, k);
            }
        }

        return total;
    }

    static getAnsweredQuestionsCountPerSection = (quizContext, sectionIdent) => {
        if (!quizContext.answers[sectionIdent]) {
            return 0;
        }
        const answeredInSection = quizContext.answers[sectionIdent];
        var answeredInSectionNotEmpty = 0;
        for (var q in answeredInSection) {
            if (Object.keys(answeredInSection[q]).length) {
                answeredInSectionNotEmpty++;
            }
        }
        return answeredInSectionNotEmpty;
    }


    static getWaypointAllAndCorrectAnswersCount = (quizContext, groupIdent, waypointIdent) => {
        const answeredInWaypoint = quizContext.answers[groupIdent][waypointIdent];
        var correctQuestions = [];
        var count = 0;
        for (var q in answeredInWaypoint) {
            let question = answeredInWaypoint[q];
            count++;
            if (this.isQuestionCorrect(question)) {
                correctQuestions.push(question);
            }
        }

        return { correct: correctQuestions.length, total: count };
    }

    static getWaypointScore = (quizContext, groupIdent, waypointIdent) => {
        if (!quizContext.answers[groupIdent]) {
            return 0;
        }
        if (!quizContext.answers[groupIdent][waypointIdent]) {
            return 0;
        }

        const { correct, total } = this.getWaypointAllAndCorrectAnswersCount(quizContext, groupIdent, waypointIdent);


        return Math.ceil(correct / total * 100);
    }

    static getTrailScore = (quizContext, groupIdent) => {
        if (!quizContext.answers[groupIdent]) {
            return 0;
        }

        var trailCorrect = 0;
        var trailTotal = 0;
        Object.keys(quizContext.answers[groupIdent]).forEach((waypointIdent) => {
            const { correct, total } = this.getWaypointAllAndCorrectAnswersCount(quizContext, groupIdent, waypointIdent);
            trailCorrect += correct;
            trailTotal += total;
        });

        return Math.ceil(trailCorrect / trailTotal * 100);

    }


    static isQuestionCorrect(question) {
        let answerIdents = Object.keys(question);
        if (!answerIdents.length) { // no answers
            return false;
        }

        const firstAnswer = question[answerIdents[0]];
        const minimumAnswers = Number(firstAnswer.minimumAnswers);

        if (minimumAnswers !== answerIdents.length) { // wrong number of answers
            return false;
        }


        for (var a in question) { // all needs to be correct
            let answer = question[a];
            if (!answer.isCorrect) { // !== 'yes'
                return false;
            }
        }

        return true;
    }

    static resetQuiz(quizContext) {

        quizContext.lastTotalProgress = 0; // reset
        quizContext.lastSectionProgress = 0; // reset

        quizContext.answers = {};
        localStorage.removeItem("app_state_answers");
    }
}