import React, { memo, lazy, Suspense } from 'react';

// content types
import ImageType from './contentTypes/Image';
//import VideoType from './contentTypes/Video';
//import ZoomImageType from './contentTypes/ZoomImage';
//import Image360type from './contentTypes/Image360';

const renderLoader = () => <div data-vjs-player className="content-video"><div className="video-container"><div className="lds-ring"><div></div><div></div><div></div><div></div></div></div></div>;

const Content = ({ detail, type, contentJson, media, click }) => {

    switch (type) {
        default:
        case 'image':
            return (<ImageType key={1} detail={detail} type={type} contentJson={contentJson} media={media} click={click} />);

        case 'video':
            const VideoType = lazy(() => import('./contentTypes/Video'))
            return (<Suspense fallback={renderLoader()}><VideoType key={2} detail={detail} type={type} contentJson={contentJson} media={media} click={click} /></Suspense>);

        case 'zoomImage':
            const ZoomImageType = lazy(() => import('./contentTypes/ZoomImage'))
            return (<Suspense fallback={renderLoader()}><ZoomImageType key={3} detail={detail} type={type} contentJson={contentJson} media={media} click={click} /></Suspense>);

        case 360:
        case '360':
            const Image360type = lazy(() => import('./contentTypes/Image360'))
            return (<Suspense fallback={renderLoader()}><Image360type key={4} detail={detail} type={type} contentJson={contentJson} media={media} click={click} /></Suspense>);

    }

};
export default memo(Content);

