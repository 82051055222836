import React, { memo } from 'react';
import BackgroundImage from '../BackgroundImage';
import Image from '../Image';

const ImageType = ({ detail, click, contentJson, media }) => {

    return (
        detail ?
            <div className="content-image">
                <Image json={media} />
            </div>
            :
            <BackgroundImage json={[media]} clickFn={click} />

    );
};
export default memo(ImageType); 