import React from 'react';
import { NavLink } from 'react-router-dom';

const MapLink = ({ history, url, groupId, classAdd, text, returnToTrailHomepage }) => {
    if (!classAdd) {
        classAdd = '';
    }
    if (!text) {
        text = 'Map';
    }

    var mapUrl = `/${url}/map`;
    if (returnToTrailHomepage === true) {
        mapUrl = `/${url}/map/hp`;
    }

    return (
        <div className={`map ${classAdd}`}>
            <NavLink to={mapUrl}>{text}</NavLink>
        </div>
    )
};
export default MapLink;
