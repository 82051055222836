import React, { useState, useRef } from 'react';

const Share = (props) => {

  const [showShare, setShowShare] = useState(false);
  const mailtoRef = useRef(null)
  let pageUrl = encodeURIComponent(document.URL);

  const showHideShare = (event) => {
    if (event.type === 'click' || event.key === 'Enter' || event.key === ' ') {
      setShowShare(true)
    }
  }

  //  onMouseOut={() => setShowShare(false)}

  const openSocialWindow = (url) => {
    const wWidth = Math.min(600, window.screen.width);

    const left = (window.screen.width - wWidth) / 2;
    const top = (window.screen.height - 500) / 2;

    var params = "menubar=no,toolbar=no,status=no,width=" + wWidth + ",height=500,top=" + top + ",left=" + left;
    window.open(url, "shareWindow", params);
  };


  const shareByMail = (event) => {
    if (event.type === 'click' || event.key === 'Enter' || event.key === ' ') {
      var mailto = 'mailto:?subject=';
      if (props.homepage) {
        mailto += encodeURIComponent('Explore the RAF Museum at your own pace');
        mailto += '&body=' + encodeURIComponent('Explore the RAF Museum at your own pace with the RAF Museum Mobile Guide. ') +pageUrl
      } else if ( props.completed ) {
        mailto += encodeURIComponent('I just scored ' + props.completed + '% in the RAF Museum '+props.title+'. Could you do better? Try it for yourself');
        pageUrl = pageUrl.replace(encodeURIComponent('/results'),'')
        mailto += '&body=' + pageUrl;
      } else {
        pageUrl = pageUrl.replace(encodeURIComponent('/results'),'')
        mailto += encodeURIComponent('Explore the RAF Museum at your own pace');
        mailto += '&body=' + encodeURIComponent('Explore the RAF Museum at your own pace with the RAF Museum Mobile Guide. ') +pageUrl
      }

      //if (includeResults) {
     
      //}

      mailtoRef.current.href = mailto;
    }
  }

  const shareOnFb = (event) => {
    if (event.type === 'click' || event.key === 'Enter' || event.key === ' ') {
      var tweet = '';;
      if (props.homepage) {
        tweet = 'Explore the RAF Museum at your own pace. Explore the RAF Museum at your own pace.';
      } else if ( props.completed ) {
        pageUrl = pageUrl.replace(encodeURIComponent('/results'),'')
        tweet = 'I just scored ' + props.completed + '% in the RAF Museum '+props.title+'. Could you do better? Try it for yourself.';
      } else {
        tweet = 'Explore the RAF Museum at your own pace. Explore the RAF Museum at your own pace.';
        pageUrl = pageUrl.replace(encodeURIComponent('/results'),'')
      }

      const url = "https://www.facebook.com/sharer.php?u=" + pageUrl;
      //const url = "https://www.facebook.com/dialog/feed?app_id=770972223331451&display=popup&caption=#RAFMtours&link=http://aircadetquiz.code8.cz&description=" + encodeURIComponent(tweet) + "";
      openSocialWindow(url);
    }
  }

  const shareOnTw = (event) => {
    if (event.type === 'click' || event.key === 'Enter' || event.key === ' ') {
      var tweet = '';

      if (props.homepage) {
        tweet = 'Explore the @RAFMUSEUM at your own pace with the RAF Museum Mobile Guide.';
      } else if ( props.completed ) {
        tweet = 'I just scored ' + props.completed + '% in the RAF Museum '+props.title+'. Could you do better? Try it for yourself with @RAFMUSEUM’s';
        pageUrl = pageUrl.replace(encodeURIComponent('/results'),'')
      } else {
        tweet = 'Explore the @RAFMUSEUM at your own pace with the RAF Museum Mobile Guide.';
        pageUrl = pageUrl.replace(encodeURIComponent('/results'),'')
      }

      const url = "https://twitter.com/intent/tweet?url=" + pageUrl + "&text=" + encodeURIComponent(tweet);
      openSocialWindow(url);
    }
  }

  return (
    <div className={`share ${showShare ? "clicked" : ""} ${props.text ? "with-text" : ""}`}>
      <span tabIndex="0" onClick={(event) => showHideShare(event)} onKeyDown={(event) => showHideShare(event)} aria-label="Share"><span>{props.text ? 'Share with your friends' : ''}</span></span>
      <ul className="share-options">
        <li className="fb" tabIndex={(showShare ? '0' : '-1')} onClick={(event) => shareOnFb(event)} onKeyDown={(event) => shareOnFb(event)} aria-label="Share on Facebook"></li>
        <li className="tw" tabIndex={(showShare ? '0' : '-1')} onClick={(event) => shareOnTw(event)} onKeyDown={(event) => shareOnTw(event)} aria-label="Share on Twitter"></li>

        <li className="em" aria-label="Share by email"><a ref={mailtoRef} tabIndex={(showShare ? '0' : '-1')} onClick={(event) => shareByMail(event)} onKeyDown={(event) => shareByMail(event)} target="_blank" rel="noopener noreferrer"></a></li>


      </ul>
    </div>
  )
};
export default Share;
