import React, { useContext, useRef, useEffect, useState } from 'react';

import AppContext from '../context/app/appContext';
import AppHelper from '../helpers/App';
import JSONHelper from '../helpers/JSON';

import Layout from '../layout/WithHeader';
import Head from './components/Head';
import Share from './components/Share';
import Button from './components/Button';
import ReadMore from './components/ReadMore';
import MapLink from './components/MapLink';

import BackgroundImage from './components/BackgroundImage';

/*
if (process.env.NODE_ENV !== 'production') {
    var ReactDOM = require('react-dom');
    var axe = require('react-axe');
    axe(React, ReactDOM, 1000);
}
*/
const Trail = ({ location, history }) => {

    const appContext = useContext(AppContext);

    const wrapperRef = useRef(null)
    const [imageHeight, setImageHeight] = useState(300);
    const [imageSized, setImageSized] = useState(false);

    const url = AppHelper.getUrlFromLocation(location);
    const groupJson = AppHelper.getGroupByUrl(appContext, url);
    const groupDataJson = AppHelper.getGroupDataByUrl(appContext, url);
    const groupId = groupJson.admin.id;
    const siteJson = AppHelper.getSiteByGroupUrl(appContext, url);

    let introText = JSONHelper.getSingleTextValue(groupJson, 'introduction')
    if (groupJson.duration) {
        introText += '\n\n' + groupJson.duration
    }

    // has map link? 
    var showMapLink = AppHelper.isMapLinkVisible(groupJson);


    const sizeImage = () => {
        setImageHeight(window.innerHeight - wrapperRef.current.offsetHeight - document.getElementById('head').offsetHeight - 50); // - padding
    };

    const collapseImage = () => {
        //setTimeout(()=>{
        //    
        //},300);
        setImageHeight(20);
        //setImageHeight(1);
    }


    useEffect(() => {
        sizeImage();
        setTimeout(() => {
            sizeImage();
            setImageSized(true);

        }, 200);
        window.addEventListener("resize", sizeImage);
        return () => window.removeEventListener("resize", sizeImage);
    }, []);


    return (
        <Layout>

            <Head closeUrl={siteJson.siteUrl} history={history} />

            <main className={`trail ${(groupDataJson.sort & 1) ? "odd" : "even"} ${(imageSized) ? "sized" : ""}`} role="main" aria-labelledby="headline">
                <div ref={wrapperRef}>
                    <article>
                        <strong className="headline">{JSONHelper.getSingleTextValue(siteJson, 'title')}</strong>

                        <h1 id="headline">{JSONHelper.getSingleTextValue(groupJson, 'title')}</h1>

                        <ReadMore sponsors={JSONHelper.getValue(groupJson, 'sponsors', false)} linebreaks={true} text={introText} onExpand={collapseImage} onCollapse={() => { setTimeout(() => sizeImage(), 300) }} />

                        {
                            JSONHelper.getValue(groupJson, 'shopLink', false) &&
                            <ul className="links">
                                <li><a href={JSONHelper.getValue(groupJson, 'shopLink', false)} target="_blank" rel="noopener noreferrer">Shop for related products</a></li>
                            </ul>
                        }

                    </article>

                    {showMapLink && <MapLink history={history} url={url} groupId={groupId} classAdd="on-left" text="Map of all locations" returnToTrailHomepage={true} />}

                    <Share homepage={true} />


                </div>

                <BackgroundImage height={imageHeight} json={JSONHelper.getValue(groupJson, 'multimedia', false)} />

                <div className="bottom">
                    <Button to={url + '/waypoints'} className={''} ariaLabel={'Go to first section'} text={'Start'} />
                </div>

            </main>
        </Layout>
    )
}
export default Trail;
