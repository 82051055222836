
import { useEffect } from 'react';

const TrackView = ({ location, ReactGA, children }) => {

    useEffect(() => {
        ReactGA.set({ page: location.pathname }); // Update the user's current page
        ReactGA.pageview(location.pathname); // Record a pageview for the given page 
    }, [location]);

    return (children);
};
export default TrackView;

