import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

const Button = ({ to, className, ariaLabel, text, onClick }) => {

  const [clickEvent, setClickEvent] = useState(false);
  var timer = null;


  const clicked = () => {
    if (onClick) {
      onClick();
    }
    setClickEvent(true);
    timer = setTimeout(() => {
      setClickEvent(false);
    }, 100);
  };

  useEffect(() => {

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
    // eslint-disable-next-line
  }, []);

  return (
    <NavLink to={to} className={`${className} btn red ${(clickEvent) ? "clicked" : ""}`} onClick={() => clicked()} aria-label={ariaLabel}>{text}</NavLink>
  );
};
export default Button;

