import React, { useContext, useRef, useEffect } from 'react';

import JSONHelper from '../../helpers/JSON';
import AppHelper from '../../helpers/App';

import AppContext from '../../context/app/appContext';
import QuizContext from '../../context/quiz/quizContext';

import Question from './Question';

const ResultsByWaypoint = (props) => {

  const appContext = useContext(AppContext);
  const quizContext = useContext(QuizContext);
  const groupId = props.groupId;
  const waypointId = props.waypointId;

  const waypointJson = AppHelper.getWaypointById(appContext, groupId, waypointId);
  const questions = quizContext.answers[groupId][waypointId];
  const questionsKeys = Object.keys(questions);
  //const contents = AppHelper.getWaypointContents(waypointJson);

  const scrollToRef = (ref) => {
    const yOffset = -80;
    const y = ref.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  };

  const myRef = useRef(null)

  // get contents
  useEffect(() => {
    appContext.getContents(waypointJson, groupId);
  }, []);

  useEffect(() => {

    if (props.visibleSection == waypointId) {
      setTimeout(() => {
        scrollToRef(myRef);
      }, 350);
    }

  }, [props.visibleSection]);


  return (
    <div ref={myRef} id={`single-result-${waypointId}`} className={`single-result ${props.visibleSection == waypointId ? "visible" : ""}`}>
      <div className="res-padding"><h2 tabIndex={(props.isVisible ? '0' : '-1')} onClick={(event) => props.toggle(event, waypointId)} onKeyDown={(event) => props.toggle(event, waypointId)}>{JSONHelper.getSingleTextValue(waypointJson, 'title')}</h2></div>
      <div className="single-result-questions">

        {
          questionsKeys.map((q, i) => {
            // let contentJson = contents.filter(wp => wp.admin.id === q)[0]; 
            const contentJson = AppHelper.getContentById(appContext, waypointId, q);
            const minimumAnswers = Number(JSONHelper.getValue(contentJson, 'minimumAnswers', '1'));

            return <Question
              key={i}
              callback={false}
              contentJson={contentJson}
              waypointJson={waypointJson}
              groupJson={props.groupJson}
              minimumAnswers={minimumAnswers}
              accordion={true}
            />;

          })
        }

      </div>
    </div>
  )
}
export default ResultsByWaypoint;
