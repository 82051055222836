import React from 'react';
import JSONHelper from '../../helpers/JSON';
import Image from './Image';

const Sponsor = ({ json }) => {

    var imgData = false;
    const multimedia = JSONHelper.getValue(json, 'multimedia', false);
    if (multimedia) {
        imgData = JSONHelper.getPrimaryImage(multimedia);
        imgData = imgData ? imgData : multimedia[0];
    }

    return (
        <div className="sponsor">
            <strong>{JSONHelper.getSingleTextValue(json, 'attributionText')}</strong>
            {imgData && <a href={json.link} target="_blank" rel="noopener noreferrer"><Image json={imgData} size="small" /></a>}
        </div>
    )
}
export default Sponsor;
