import React, { useEffect, useContext } from 'react';

import ProgressContext from '../../context/progress/progressContext';
import AppContext from '../../context/app/appContext';
import AppHelper from '../../helpers/App';

const ProgressBar = ({ id, ident, total, groupJson, waypointJson, waypointData, contentData, completed }) => {

  const progressContext = useContext(ProgressContext);
  const appContext = useContext(AppContext);
  
  useEffect(() => {

    var currentProgress = 0;



    if (completed === true) {
      currentProgress = 100;
    } else {
      if (total) {
        //if (waypointJson) {

        //currentProgress = ((waypointData.sort - 1) / groupJson.waypoints.length) * 100;
        currentProgress = ((AppHelper.getWaypointsVisitedCount(appContext, groupJson.admin.id) ) / groupJson.waypoints.length) * 100;
        //}
      } else {
        if (contentData) {
          const contents = AppHelper.getWaypointContents(waypointJson);
          currentProgress = ((contentData.sort ) / contents.length) * 100;
        }
      }
    }

    if (total) {
      progressContext.setGroupProcessWithDelay(currentProgress);
    } else {
      progressContext.setWaypointProcessWithDelay(currentProgress);
    }

  }, []);

  return (
    <div id={id} className={`progress-bar ${ident}`}><div style={{ width: (total ? progressContext.groupProgress : progressContext.waypointProgress) + "%" }}></div></div>
  )
};
export default ProgressBar;
