import config from '../config.js';

export default async function ElasticSearch(query) {

  var headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  };

  // get token for test
  if (!config.isLive()) {
    let loginResponse = await fetch(config.loginEndpoint,
      {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(config.userData),
      });

    let loginResponseJSON = await loginResponse.json();
    const token = loginResponseJSON.data.token;

    headers.authorization = 'Bearer ' + token;
  }

  try {
    const res = await fetch(config.getSearchURL(),
      {
        method: "POST",
        headers: headers,
        body: query,
      });

    const data = await res.json();
    return data;

  } catch (error) {
    console.warn(error);
    return [];
  }
}