import React from 'react';
import { NavLink } from 'react-router-dom';

import BackgroundImage from './BackgroundImage';

import AppHelper from '../../helpers/App';
import JSONHelper from '../../helpers/JSON';

const WaypointSearchItem = ({ no, json, group: groupJson, site: siteJson }) => {

    const waypointId = json.admin.id;
    const waypointData = AppHelper.getWaypointDataFromGroup(waypointId, groupJson);

    const url = '/' + waypointData.waypointUrl;
    const groupUrl = AppHelper.getGroupUrlFromSite(groupJson.admin.id, siteJson);

    return (
        <li className="has-location">
            <NavLink to={url}>
                <BackgroundImage json={JSONHelper.getValue(waypointData, 'multimedia', false)} />
                <div>
                    <em>{no}</em>
                    <strong><span>{JSONHelper.getSingleTextValue(json, 'title')}</span></strong>
                    <span>{JSONHelper.getSingleTextValue(waypointData, 'listSummaryText')}</span>
                </div>
            </NavLink>
            <NavLink to={groupUrl}>
                <div>
                    <strong><span>Part of {JSONHelper.getSingleTextValue(groupJson, 'title')} in {JSONHelper.getSingleTextValue(siteJson, 'title')}</span></strong>
                </div>
            </NavLink>
        </li>
    )
};
export default WaypointSearchItem;
