import React, { useReducer } from 'react';
import AudioContext from './audioContext';
import AudioReducer from './audioReducer';

import {
    PLAY_PAUSE_AUDIO,
    SET_AUDIO_SOURCE,
    SET_AUDIO_PROGRESS
} from '../types';

const AudioState = props => {
    const initialState = {
        audioPlaying: false,
        audioSrc: '',
        audioProgress: 0
    }

    const [state, dispatch] = useReducer(AudioReducer, initialState);

    // set audio play tyrue false
    const playAudio = play => {
        dispatch({
            type: PLAY_PAUSE_AUDIO,
            payload: play
        });
    };

    // set audio src
    const setAudioSource = src => {
        dispatch({
            type: SET_AUDIO_SOURCE,
            payload: src
        });
    };

    // set progress
    const setAudioProgress = progress => {
        dispatch({
            type: SET_AUDIO_PROGRESS,
            payload: progress
        });
    };

    return <AudioContext.Provider
        value={{
            playAudio: playAudio,
            audioPlaying: state.audioPlaying,
            setAudioSource: setAudioSource,
            audioSrc: state.audioSrc,
            setAudioProgress: setAudioProgress,
            audioProgress: state.audioProgress

        }}>
        {props.children}
    </AudioContext.Provider>
};

export default AudioState;