import React, { useReducer, useEffect } from 'react';
import QuizContext from './quizContext';
import QuizReducer from './quizReducer';

import {
    SET_ANSWER,
    REMOVE_ANSWERS
} from '../types';


const QuizState = props => {


    // import state from LocalStorage if exists

    const localState = JSON.parse(localStorage.getItem("quiz_state"));

    const initialState = {
        answers: {}
    }

    const [state, dispatch] = useReducer(QuizReducer, localState || initialState);

    const anserQuestion = data => {
        dispatch({
            type: SET_ANSWER,
            payload: data
        });
    };

    const removeAnswers = groupId => {
        dispatch({
            type: REMOVE_ANSWERS,
            payload: { id: groupId }
        });
    };

    useEffect(() => {
        localStorage.setItem("quiz_state", JSON.stringify(state));
    }, [state]);

    return <QuizContext.Provider
        value={{
            answers: state.answers,
            anserQuestion: anserQuestion,
            removeAnswers: removeAnswers
        }}>
        {props.children}
    </QuizContext.Provider>
};

export default QuizState;