import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';

import Layout from '../layout/WithHeader';
import Head from './components/Head';
import Button from './components/Button';
import ProgressBar from './components/ProgressBar';
import Chart from './components/Chart';

import AppContext from '../context/app/appContext';
import QuizContext from '../context/quiz/quizContext';

import AppHelper from '../helpers/App';
import JSONHelper from '../helpers/JSON';
import QuizHelper from '../helpers/Quiz';

/*
if (process.env.NODE_ENV !== 'production') {
    var ReactDOM = require('react-dom');
    var axe = require('react-axe');
    axe(React, ReactDOM, 1000);
}
*/

const WaypointResult = ({ location, history }) => {

    const appContext = useContext(AppContext);
    const quizContext = useContext(QuizContext);

    const waypointUrl = AppHelper.getFirstUrlFromLocation(location);

    const groupJson = AppHelper.getGroupByWaypointUrl(appContext, waypointUrl);
    const groupId = groupJson.admin.id;
    const groupWaypoints = groupJson.waypoints;

    const waypointData = AppHelper.getWaypointDataFromGroupByUrl(waypointUrl, groupJson);

    const groupUrl = '/' + AppHelper.getGroupUrlById(appContext, groupId);
    const waypointsUrl = groupUrl + '/waypoints';
    const groupResultsUrl = groupUrl + '/results';
    const directionsUrl = '/' + waypointUrl;

    const exitUrl = '/' + waypointUrl + '/exit';

    // has questions / amswers / is quiz ?
    var score = 0;
    const groupIdent = groupJson.admin.id;
    const waypointIdent = waypointData.waypoint.admin.id;
    var isQuizWaypoint = false;
    //if (groupJson.locationType == "Quiz"){ 
    if (quizContext.answers[groupIdent] && quizContext.answers[groupIdent][waypointIdent]) {
        isQuizWaypoint = true;
        score = QuizHelper.getWaypointScore(quizContext, groupIdent, waypointIdent);
    }


    // get next url 
    //const nextWaypoint = AppHelper.getContentBySort(groupWaypoints, waypointData.sort + 1); 
    const nextWaypoint = AppHelper.getNextWaypoint(appContext, groupId, waypointData, groupWaypoints);
    const nextUrl = nextWaypoint ? '/' + nextWaypoint.waypointUrl : groupResultsUrl;

    // next button text
    var nextText = 'Next';
    if (nextWaypoint) {
        nextText = 'Next location';
    } else {
        if (isQuizWaypoint) {
            if (!nextWaypoint) {
                nextText = 'Finish quiz';
            }
        }
    }



    return (
        <Layout>

            <Head exitUrl={exitUrl} backUrl={directionsUrl} history={history} showProgess={true} groupJson={groupJson} waypointJson={false} waypointData={waypointData} completed={false} />

            <main className={`waypoint ${(waypointData.sort & 1) ? "odd" : "even"}`} role="main" aria-labelledby="headline">
                <article>
                    <h1 id="headline">{JSONHelper.getValue(waypointData.waypoint, 'summary_title')}</h1>

                    <ProgressBar id="" ident="progress-section" total={false} groupJson={groupJson} waypointJson={false} waypointData={waypointData} contentData={false} completed={true} />

                    <div className="text closer">

                        {
                            isQuizWaypoint &&
                            <h2>You’ve completed all questions in {JSONHelper.getValue(waypointData.waypoint, 'summary_title')}</h2>
                        }

                        {
                            !isQuizWaypoint &&
                            <h2>You've seen all content at location {AppHelper.getWaypointsVisitedCount(appContext, groupJson.admin.id) /*waypointData.sort*/} of {groupWaypoints.length} of {JSONHelper.getSingleTextValue(groupJson, 'title')}</h2>
                        }
                        {
                            /*
                            <h2>More</h2>
                            <p>More information on the Battle of Britain Monument here:</p>
                            <ul className="links">
                                <li><a href="http://bbm.org.uk" target="_blank">http://bbm.org.uk</a></li>
                            </ul>
              
                            <h2>Next</h2>
                            <p>The next stop is the Royal Airforce Memorial. Continue by clicking next or </p>
                            */
                        }

                        {
                            isQuizWaypoint &&
                            <div className="quiz-results">
                                <article>
                                    <h4 className="headline">Your score is:</h4>
                                </article>
                                <Chart score={score} />
                            </div>
                        }

                        {
                            !isQuizWaypoint &&
                            <div className="quiz-results">
                                <article>
                                    <h4 className="headline">{JSONHelper.getValue(waypointData.waypoint, 'summary_title')} is part of <NavLink to={waypointsUrl}>{JSONHelper.getSingleTextValue(groupJson, 'title')}</NavLink>.</h4>
                                </article>
                            </div>
                        }

                        <ul className="links">
                            <li><NavLink to={waypointsUrl}>back to overview</NavLink></li>
                        </ul>

                    </div>

                </article>

                <div className="bottom">
                    <Button to={nextUrl} className={''} ariaLabel={'Continue'} text={nextText} />
                </div>

            </main>

        </Layout>
    )
}
export default WaypointResult;