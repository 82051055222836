import React, { useState, useEffect, useContext } from 'react';
import { Redirect } from "react-router-dom";

import Layout from '../layout/WithHeader';
import Head from './components/Head';
import Button from './components/Button';
import Textnl2p from './components/Textnl2p';
import FullwidthImage from './components/FullwidthImage';

import ProgressContext from '../context/progress/progressContext';
import AppContext from '../context/app/appContext';
import AppHelper from '../helpers/App';
import JSONHelper from '../helpers/JSON';

/*
if (process.env.NODE_ENV !== 'production') {
    var ReactDOM = require('react-dom');
    var axe = require('react-axe');
    axe(React, ReactDOM, 1000);
}
*/

const arePointsNear = (checkPoint, centerPoint, km) => {
    var ky = 40000 / 360;
    var kx = Math.cos(Math.PI * centerPoint.lat / 180.0) * ky;
    var dx = Math.abs(centerPoint.lng - checkPoint.lng) * kx;
    var dy = Math.abs(centerPoint.lat - checkPoint.lat) * ky;
    return Math.sqrt(dx * dx + dy * dy) <= km;
}

const Directions = ({ location, history }) => {

    const [waypointJson, setWaypointJson] = useState(false);
    const [contentUrl, setContentUrl] = useState('');
    const [contentDataJson, setContentDataJson] = useState({});
    const [waypointContents, setWaypointContents] = useState([]);

    const appContext = useContext(AppContext);
    const progressContext = useContext(ProgressContext);

    const waypointUrl = AppHelper.getFirstUrlFromLocation(location);

    const groupJson = AppHelper.getGroupByWaypointUrl(appContext, waypointUrl);
    const groupId = groupJson.admin.id;
    const waypointData = AppHelper.getWaypointDataFromGroupByUrl(waypointUrl, groupJson);

    const groupUrl = '/' + AppHelper.getGroupUrlById(appContext, groupId);
    const waypointsUrl = groupUrl + '/waypoints';
    const prevWaypoint = AppHelper.getPreviousWaypoint(appContext, groupId, waypointData, groupJson.waypoints);
    var backUrl = groupUrl + '/waypoints';
    var backText = '';
    if (prevWaypoint) {
        backText = AppHelper.getPreviousWaypointTitle(prevWaypoint);
        backUrl = '/' + prevWaypoint.waypointUrl;
    }

    const exitUrl = '/' + waypointUrl + '/exit';

    const mapType = groupJson.mapType;
    var mapLocation = false;



    const resetVisitedCallback = (event) => {
        // reset visited when going back 
        console.log('reseting');
        appContext.waypointsVisitedReset(groupId);
    }

    //const waypointData = AppHelper.getWaypointByUrl(appContext, waypointUrl);
    var multimedia = JSONHelper.getValue(waypointData, 'multimedia', false);

    if (mapType === 'mapbox' && waypointData.waypointLocation) {
        mapLocation = waypointData.waypointLocation; // lat lng address
    }

    //console.log(groupJson); console.log(waypointData); console.log(waypointJson);

    // get waypoints 
    useEffect(() => {
        appContext.getWaypoints(groupJson);

        window.onpopstate = e => {
            appContext.waypointsVisitedReset(groupId);
        }

        return () => {
            window.onpopstate = e => { };
        };
    }, []);

    useEffect(() => {
        let json = AppHelper.getWaypointById(appContext, groupId, waypointData.waypoint.admin.id);

        if (json && json.admin) {
            setWaypointJson(json);
            appContext.getContents(json, groupId);
        }
        progressContext.setWaypointProcess(0);

    }, [appContext.waypoints]);

    // get firest content, skip themes
    if (waypointJson && !contentUrl.length) {
        const contents = AppHelper.getWaypointContents(waypointJson);
        const firstContent = contents[0];
        const u = AppHelper.getContentUrl(waypointUrl, firstContent);

        if (AppHelper.waypointHasDirections(groupJson, waypointData)) {
            setContentUrl(u);
            setContentDataJson(firstContent);
            setWaypointContents(contents);
        } else {
            return <Redirect to={u} />
        }

    }

    const showCityMapper = arePointsNear({ lat: mapLocation.lat, lng: mapLocation.lng }, { lat: 51.509865, lng: -0.118092 }, 20)
    //{waypointContents.length > 1 && <ProgressBar ident="progress-section" total={false} completed={0} groupJson={groupJson} waypointJson={waypointJson} contentData={contentDataJson} />}
    //waypointData.whatToExpectLink = 'https://www.nationalarchives.gov.uk/';

    return (
        <Layout>
            {waypointJson && contentDataJson &&
                <React.Fragment>
                    <Head exitUrl={exitUrl} backUrl={backUrl} backCallback={resetVisitedCallback} backText={backText} history={history} showProgess={true} groupJson={groupJson} waypointJson={waypointJson} waypointData={waypointData} completed={false} />

                    <main className={`directions ${(waypointData.sort & 1) ? "odd" : "even"}`} role="main" aria-labelledby="headline">
                        <article>
                            <h1 id="headline">{JSONHelper.getSingleTextValue(waypointJson, 'title')}</h1>

                            <FullwidthImage json={multimedia} />

                            <div className="text">




                                {JSONHelper.getSingleTextValue(waypointData, 'whatToExpectText', '').length > 0 &&
                                    <React.Fragment>
                                        <h2>What to expect</h2>
                                        <Textnl2p text={JSONHelper.getSingleTextValue(waypointData, 'whatToExpectText')} />

                                    </React.Fragment>
                                }
                                {JSONHelper.getValue(waypointData, 'whatToExpectLink', '').length > 0 &&
                                    <ul className="links">
                                        <li><a href={JSONHelper.getValue(waypointData, 'whatToExpectLink')} target="_blank">{JSONHelper.getValue(waypointData, 'whatToExpectLink').replace('https://', '').replace('http://', '')}</a></li>
                                    </ul>
                                }

                                <h2>Directions</h2>
                                <Textnl2p text={JSONHelper.getSingleTextValue(waypointData, 'directionsText', '')} />

                                {mapLocation &&
                                    <ul className="links">
                                        {showCityMapper && <li><a href={`https://citymapper.com/directions?endcoord=${mapLocation.lat}%2C${mapLocation.lng}&endname=&endaddress=${encodeURI(mapLocation.address)}`} target="_blank">Get me there with CityMapper</a></li>}
                                        <li><a href={`https://maps.google.com/?saddr=Current+Location&daddr=${mapLocation.lat},${mapLocation.lng}`} target="_blank">Get me there with Google maps</a></li>
                                    </ul>
                                }

                                {multimedia.length > 1 &&
                                    <ul className="more-images">
                                        {multimedia.map((mj, i) => {
                                            let image = [mj];
                                            if (i > 0) { // stkip first
                                                return (<FullwidthImage key={i} json={image} />)
                                            }
                                        })}
                                    </ul>
                                }

                            </div>

                        </article>



                        <div className="bottom">
                            <Button to={contentUrl} className={''} ariaLabel={'Continue'} text={'I’ve arrived'} />
                        </div>

                    </main>
                </React.Fragment>
            }

        </Layout>
    )
}
export default Directions;