import JSONHelper from './JSON';

export default class AppHelper {

    static getSiteByUrl = (appContext, url) => {
        let site = appContext.sites.filter(site => site.siteUrl === url);
        return site.length ? site[0] : false;
    }

    static getSiteByGroupUrl = (appContext, url) => {
        // loop throu sites
        var site = false;
        appContext.sites.some(function (s) {
            let group = s.groups.filter(g => g.groupUrl === url);
            if (group.length) {
                site = s;
                return true;
            }
        });

        return site;
    }

    static getSiteByGroupId = (appContext, id) => {
        // loop throu sites
        var site = false;
        appContext.sites.some(function (s) {
            if (s.groups) {
                let group = s.groups.filter(g => g.group.admin.id === id);
                if (group.length) {
                    site = s;
                    return true;
                }
            }
        });

        return site;
    }

    static getGroupUrlById = (appContext, id) => {

        var groupUrl = false;
        appContext.sites.some(function (s) {
            let group = s.groups.filter(g => g.group.admin.id === id);
            if (group.length) {
                groupUrl = group[0].groupUrl;
                return true;
            }
        });

        return groupUrl;
    }

    static getGroupDataByUrl = (appContext, url) => {
        // loop throu sites
        var groupData = false;
        appContext.sites.some(function (s) {
            let group = s.groups.filter(g => g.groupUrl === url);
            if (group.length) {
                groupData = group[0];
                return true;
            }
        });

        return groupData;
    }

    static getGroupByUrl = (appContext, url) => {
        // loop throu sites
        var groupIdent = false;
        appContext.sites.some(function (s) {
            let group = s.groups.filter(g => g.groupUrl === url);
            if (group.length) {
                groupIdent = group[0].group.admin.id;
                return true;
            }
        });

        if (!groupIdent) {
            return false;
        }
        // get group by ident
        return this.getContentByAdminId(appContext, groupIdent, 'groups');
    }

    static getGroupByWaypointUrl = (appContext, url) => {


        // loop throu groups
        var groupIdent = false;
        appContext.groups.some(function (group) {
            let wp = group.waypoints.filter(w => w.waypointUrl === url);
            if (wp.length) {
                groupIdent = group.admin.id;
                return true;
            }
        });

        if (!groupIdent) {
            return false;
        }
        // get group by ident
        return this.getContentByAdminId(appContext, groupIdent, 'groups');
    }

    static getGroupByWaypointId = (appContext, id) => {
        // loop throu groups
        var groupIdent = false;
        appContext.groups.some(function (group) {
            let wp = group.waypoints.filter(w => w.waypoint.admin.id === id);
            if (wp.length) {
                groupIdent = group.admin.id;
                return true;
            }
        });

        if (!groupIdent) {
            return false;
        }
        // get group by ident
        return this.getContentByAdminId(appContext, groupIdent, 'groups');
    }

    static getWaypointById = (appContext, groupId, id) => {

        if (!appContext.waypoints[groupId]) {
            return false;
        }
        let content = appContext.waypoints[groupId].filter(wp => wp.admin.id === id);
        return content[0] ? content[0] : false;
    }

    static getWaypointsVisitedCount = (appContext, groupId) => {
        if (!appContext.waypoints[groupId]) {
            return 0;
        }
        return appContext.waypoints[groupId].filter(wp => wp.visited === true).length;
    }



    static getGroupUrlFromSite = (id, siteJson) => {
        if (!siteJson.groups) {
            return false;
        }
        let groupRef = siteJson.groups.filter(g => g.group.admin.id === id);
        return groupRef.length ? '/' + groupRef[0].groupUrl : false;
    }

    static getUrlFromLocation = (location) => {
        return location.pathname.substr(1);
    }

    static getFirstUrlFromLocation = (location) => {
        return location.pathname.substr(1).split('/').shift();
    }

    static getSecondUrlFromLocation = (location) => {
        return location.pathname.substr(1).split('/')[1];
    }
    static getThirthUrlFromLocation = (location) => {
        return location.pathname.substr(1).split('/')[2];
    }
    static getFourthUrlFromLocation = (location) => {
        return location.pathname.substr(1).split('/')[3];
    }

    static getWaypointDataFromGroup = (id, groupJson) => {
        if (!groupJson) {
            return false;
        }
        let wpRef = groupJson.waypoints.filter(w => w.waypoint.admin.id === id);
        return wpRef.length ? wpRef[0] : false;
    }

    static getWaypointDataFromGroupByUrl = (url, groupJson) => {
        if (!groupJson) {
            return false;
        }
        let wpRef = groupJson.waypoints.filter(w => w.waypointUrl === url);
        return wpRef.length ? wpRef[0] : false;
    }

    static getWaypointContents = (waypointJson) => {
        if (!waypointJson || !waypointJson.themes) {
            return [];
        }
        var contents = [];
        // fix sort, multiple themes
        var sort = 1;
        waypointJson.themes.forEach((t, index) => {
            if (t.contents && t.contents.length) {
                t.contents.forEach((cn, index) => {
                    cn.sort = sort;
                    contents.push(cn);
                    sort++;
                });

                //contents = contents.concat(t.contents);

            }
        });
        return contents;
    }

    static fixSorts = (arr) => {
        var sort = 1;
        arr.forEach((t, index) => {
            t.sort = sort;
            sort++;
        });
        return arr;
    }

    static getContentByUrl = (contents, url) => {
        if (!contents || !contents.length) {
            return false;
        }
        let c = contents.filter(cc => cc.contentUrl === url);
        return c.length ? c[0] : false;
    }

    static getContentById = (appContext, waypointId, id) => {
        if (!appContext.contents[waypointId]) {
            return false;
        }
        let content = appContext.contents[waypointId].filter(wp => wp.admin.id === id);
        return content[0] ? content[0] : false;
    }

    static getContentBySort = (contents, sort) => {
        if (!contents || !contents.length) {
            return false;
        }

        let c = contents.filter(cc => cc.sort === sort);
        return c.length ? c[0] : false;
    }

    static getNextWaypoint = (appContext, groupId, waypoint, waypoints, isQuiz) => {
        if (!appContext.waypoints[groupId]) {
            return false;
        }

        const notVisited = appContext.waypoints[groupId].filter(wp => wp.visited !== true);

        //var nextWaypoint = this.getContentBySort(waypoints, waypoint.sort + 1);
        if (!notVisited.length || appContext.waypoints[groupId].length === 1) {
            return false;
        }

        let w = notVisited.filter(cc => cc.sort === waypoint.sort + 1);

        if (w.length) {
            return this.getContentBySort(waypoints, w[0].sort);
        } else {
            return this.getContentBySort(waypoints, notVisited[0].sort);
        }

    }


    static getPreviousWaypoint = (appContext, groupId, waypoint, waypoints) => {
        if (!appContext.waypoints[groupId]) {
            return false;
        }
        return this.getContentBySort(waypoints, waypoint.sort - 1);
    }

    static getPreviousWaypointTitle = (waypoint) => {
        if (!waypoint) {
            return '';
        }
        if (!waypoint.waypoint) {
            return '';
        }

        return waypoint.waypoint.summary_title;
    }

    static getContentUrl = (waypointUrl, content) => {
        return '/' + waypointUrl + '/' + content.contentUrl;
    }


    static getAdminIdsFromRefs = (json, prop, key) => {
        let ids = [];
        if (json[prop]) {
            json[prop].forEach((g, index) => {
                ids.push(JSONHelper.getAdminId(g[key]));
            });
        }
        return ids;
    }

    static getSortFromRefs = (json, prop, key) => {
        let sorts = {};
        if (json[prop]) {
            json[prop].forEach((g, index) => {
                sorts[JSONHelper.getAdminId(g[key])] = g.sort;
            });
        }
        return sorts;
    }

    static getContentByAdminId = (appContext, id, prop) => {
        let content = false;
        if (appContext[prop]) {
            content = appContext[prop].filter(content => content.admin.id === id);
            content = content[0] ? content[0] : false;
        }
        return content;
    }

    static getContentsByAdminIds = (appContext, ids, prop) => {
        let contents = [];
        if (appContext[prop]) {

            // not preserving order of ids
            // contents = appContext[prop].filter(content => ids.includes(content.admin.id));

            for (var i in ids) {
                let content = this.getContentByAdminId(appContext, ids[i], prop);
                if (content) {
                    contents.push(content);
                }
            }
        }
        return contents;
    }

    static waypointHasDirections = (groupJson, waypointData) => {

        if (
            JSONHelper.getSingleTextValue(waypointData, 'directionsText').length ||
            (groupJson.mapType === 'mapbox' && waypointData.waypointLocation && JSONHelper.getValue(waypointData, 'multimedia', false)) ||
            JSONHelper.getSingleTextValue(waypointData, 'whatToExpectText').length
        ) {
            return true;
        } else {
            return false;
        }
    }

    static isMapLinkVisible = (groupJson) => {

        const mapType = groupJson.mapType;
        var showMapLink = false;

        if (mapType === 'mapbox') {
            var points = [];
            // eslint-disable-next-line
            groupJson.waypoints.map((wpJson, i) => {
                if (wpJson.waypointLocation) {
                    points.push([Number(wpJson.waypointLocation.lng), Number(wpJson.waypointLocation.lat)]);
                }
            });
            showMapLink = points.length > 0;
        } else if (/*mapType === 'local' && */groupJson.maps && groupJson.maps.length) {
            showMapLink = true;
        }

        return showMapLink;
    }

    static connectTexts = (arr) => {
        if (arr.length === 1) return arr[0];
        const firsts = arr.slice(0, arr.length - 1);
        const last = arr[arr.length - 1];
        return firsts.join(', ') + ' and ' + last;
    }

}