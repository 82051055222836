import React, { useState, useEffect, useContext, useRef } from 'react';
import Layout from '../layout/Modal';
import Head from './components/Head';
import TrailItem from './components/TrailItem';
import WaypointSearchItem from './components/WaypointSearchItem';

import AppContext from '../context/app/appContext';
import JSONHelper from '../helpers/JSON';
import AppHelper from '../helpers/App';

import ElasticSearch from '../data/ElasticSearch.js';


/*
if (process.env.NODE_ENV !== 'production') {
    var ReactDOM = require('react-dom');
    var axe = require('react-axe');
    axe(React, ReactDOM, 1000);
}
*/

const Search = ({ location, history }) => {

    const appContext = useContext(AppContext);

    var timer = useRef(false);
    const WAIT_INTERVAL = 1000;
    const ENTER_KEY = 13;

    // display only content's we can handle
    var knownGroupsWaypointsIds = useRef([]);

    const [isLoading, setIsLoading] = useState(false);
    const [noResults, setNoResults] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [searchResults, setSearchResults] = useState([]);

    const handleChange = (event) => {
        const value = event.target.value;

        clearTimeout(timer.current);
        setSearchText(value);

        if (value.length) {
            timer.current = setTimeout(() => {
                performSearch(value);
            }, WAIT_INTERVAL);
        }

    }

    const handleKeyDown = (event) => {
        if (event.keyCode === ENTER_KEY) {
            performSearch(searchText);
        }
    }

    const performSearch = async (search_term) => {

        setIsLoading(true);

        //const query = '{"query" : {"terms" : {"admin.id" : ["' + 'raf-wp-6151' + '"] } }, "size": 1 }';
        const search_query = `{
            "query_string":{"default_field":"_all","query":"${search_term}"}
        }`;

        const query = `{
            "query":{
                "bool":{
                    "must":[
                        {
                            "terms":{
                                "type.base": ["cb-group", "cb-waypoint"]
                            }
                        },
                        {
                            "terms":{
                                "admin.id": ["${knownGroupsWaypointsIds.current.join('", "')}"]
                            }
                        },
                        ${search_query}
                    ],
                    "must_not":[],
                    "should":[]
                }
            },
            "from":0,
            "size":50,
            "sort":[],
            "aggs":{}
        }`;


        const res = await ElasticSearch(query);
        setSearchResults(JSONHelper.getHits(res));

        if (res && res.hits && res.hits && res.hits.hits.length) {
            setNoResults(false);
        } else {
            setNoResults(true);
        }

        setIsLoading(false);
    };

    const escaping = (event) => {
        if (event.keyCode === 27) {
            // go back
            goBack();
        }
    }

    const goBack = (event) => {
        history.goBack();
    };

    const initKnownGroupsWaypointsIds = () => {

        if (!appContext.groups) { return []; }
        if (!appContext.groups.length) { return []; }

        appContext.groups.forEach((g, index) => {

            knownGroupsWaypointsIds.current.push(g.admin.id);

            if (g.waypoints && g.waypoints.length) {
                g.waypoints.forEach((w, index2) => {
                    knownGroupsWaypointsIds.current.push(w.waypoint.admin.id);
                });
            }

            //console.log(knownGroupsWaypointsIds);
        });

    };

    useEffect(() => {

        // get groups and waypoints in the app
        initKnownGroupsWaypointsIds();


        window.addEventListener('keydown', escaping);

        return () => {
            window.removeEventListener('keydown', escaping);

            if (timer && timer.current) {
                clearTimeout(timer.current);
            }
        }

        // eslint-disable-next-line
    }, []);

    return (
        <Layout>
            <Head closeCallback={goBack} disableLogo={true} history={history} />

            <main className="search-page" role="main" aria-labelledby="headline">

                <div id="search">
                    <div className="content">
                        <h1 id="headline">Search</h1>
                        <label className="h2" htmlFor="search-text">Search for a location</label>
                        <fieldset>
                            <input
                                type="search"
                                name="s"
                                placeholder="Type here"
                                value={searchText}
                                id="search-text"

                                onChange={(event) => handleChange(event)}
                                onKeyDown={(event) => handleKeyDown(event)}
                            />
                        </fieldset>
                        <div id="search-container">
                            {isLoading &&
                                <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                            }
                            {!isLoading &&
                                <div id="search-results">
                                    {noResults && <h4>Sorry, no results found - try a different search...</h4>}

                                    {searchResults.length > 0 &&
                                        <ul className="trails-list">
                                            {searchResults.map((content, i) => {
                                                // group or waypoint?
                                                if (content._source.type.base === 'cb-group') {

                                                    return (<TrailItem key={i} no={i + 1} showLocation={true} json={content._source} site={false} />);

                                                } else if (content._source.type.base === 'cb-waypoint') {

                                                    let waypointId = content._source.admin.id;
                                                    let groupJson = AppHelper.getGroupByWaypointId(appContext, waypointId);
                                                    let siteJson = AppHelper.getSiteByGroupId(appContext, groupJson.admin.id);
                                                    return (<WaypointSearchItem key={i} no={i + 1} json={content._source} group={groupJson} site={siteJson} />);

                                                }

                                            })}
                                        </ul>
                                    }
                                </div>
                            }
                        </div>

                    </div>
                </div>
            </main>
        </Layout>
    )
};
export default Search;
