import React, { useEffect, useContext } from 'react';
import { NavLink } from 'react-router-dom';

import AppContext from '../context/app/appContext';
import AudioContext from '../context/audio/audioContext';
import QuizContext from '../context/quiz/quizContext';
import AppHelper from '../helpers/App';

import Layout from '../layout/Modal';
import Head from './components/Head';


/*
if (process.env.NODE_ENV !== 'production') {
    var ReactDOM = require('react-dom');
    var axe = require('react-axe');
    axe(React, ReactDOM, 1000);
}
*/

const Exiting = ({ location, history }) => {

    const appContext = useContext(AppContext);
    const audioContext = useContext(AudioContext);
    const quizContext = useContext(QuizContext);

    const url = AppHelper.getFirstUrlFromLocation(location);
    var groupJson = AppHelper.getGroupByUrl(appContext, url);
    var quitUrl, finishUrl, exitUrl, groupUrl, isQuiz = false;


    if (groupJson === false) { // not a group = waypoint

        groupJson = AppHelper.getGroupByWaypointUrl(appContext, url);
        groupUrl = '/' + AppHelper.getGroupUrlById(appContext, groupJson.admin.id);
        quitUrl = groupUrl + '';
        finishUrl = '/' + url + '/result';

    } else { // group 
        groupUrl = '/' + AppHelper.getGroupUrlById(appContext, groupJson.admin.id);
        exitUrl = quitUrl = '/' + url + '';
        finishUrl = '/' + url + '/results';
    }
    const groupId = groupJson.admin.id;
    const siteJson = AppHelper.getSiteByGroupId(appContext, groupId);
    const groupResultsUrl = groupUrl + '/results';

    exitUrl = '/' + siteJson.siteUrl;

    // quiz with some questions answered 
    if (groupJson.locationType == "Quiz") {
        isQuiz = true;
        // exit is whole quiz result, not waypoint
        finishUrl = groupResultsUrl;

    }
    const locationType = groupJson.locationType ? groupJson.locationType : 'trail';

    const escaping = (event) => {
        if (event.keyCode === 27) {
            // go back
            goBack();
        }
    }

    const resetProgress = () => {
        appContext.waypointsVisitedReset(groupId);
        quizContext.removeAnswers(groupId);
    };

    const goBack = (event) => {
        history.goBack();
    };

    const stopAudio = (event) => {
        audioContext.playAudio(false);
        audioContext.setAudioSource('');
        audioContext.setAudioProgress(0);

        resetProgress(event);
    };

    useEffect(() => {
        window.addEventListener('keydown', escaping);
        return () => {
            window.removeEventListener('keydown', escaping);
        }
    }, []);

    return (
        <Layout>
            <Head closeCallback={goBack} disableLogo={true} history={history} />

            <main className="search-page" role="main" aria-labelledby="headline">
                <div id="exiting" className="visible">
                    <div className="content">
                        <article>
                            <h1 id="headline">Are you sure you want to exit?</h1>
                            {!isQuiz && <p>Select 'RESTART' to restart the <span className="strtolower">{locationType} </span>, or select 'EXIT' to choose another trail, quiz or exhibition.</p>}
                            {
                                isQuiz && <p>
                                    Select 'RESTART' to restart the quiz, select 'QUIT AND GET SCORE' to stop answering questions and see how you've done,
                                    or select 'EXIT' to choose another trail, quiz or exhibition.
                            </p>
                            }

                        </article>

                        <div className="centerv stack">
                            <NavLink tabIndex="0" onClick={(event) => stopAudio(event)} to={quitUrl} className="red btn na" aria-label="Quit">RESTART</NavLink>
                            {isQuiz && <NavLink tabIndex="0" to={finishUrl} className="red btn na" aria-label="Finish">QUIT AND GET SCORE</NavLink>}
                            <NavLink tabIndex="0" onClick={(event) => resetProgress(event)} to={exitUrl} className="red btn na" aria-label="Finish">EXIT</NavLink>
                        </div>
                    </div>
                </div>
            </main>
        </Layout>
    )
};
export default Exiting;
