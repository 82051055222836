// core / app
export const SET_TRANSITION = 'SET_TRANSITION';
export const SET_LOADING = 'SET_LOADING';
export const SET_SITES_AND_GROUPS = 'SET_SITES_AND_GROUPS';
export const SET_WAYPOINTS = 'SET_WAYPOINTS';
export const SET_CONTENTS = 'SET_CONTENTS';

// site vs category
export const SET_SITE_CATEGORY = 'SET_SITE_CATEGORY';

//gallery
export const SET_SLIDE = 'SET_SLIDE';

// progress
export const SET_GROUP_PROGRESS = 'SET_GROUP_PROGRESS';
export const SET_WAYPOINT_PROGRESS = 'SET_WAYPOINT_PROGRESS';
export const SET_WAYPOINT_VISITED = 'SET_WAYPOINT_VISITED';
export const RESET_WAYPOINT_VISITED = 'RESET_WAYPOINT_VISITED';



// audio
export const PLAY_PAUSE_AUDIO = 'PLAY_PAUSE_AUDIO';
export const SET_AUDIO_SOURCE = 'SET_AUDIO_SOURCE';
export const SET_AUDIO_PROGRESS = 'SET_AUDIO_PROGRESS';

// quiz
export const SET_ANSWER = 'SET_ANSWER';
export const REMOVE_ANSWERS = 'REMOVE_ANSWERS'; 
