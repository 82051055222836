import React, { useEffect, useContext } from 'react';
import { NavLink } from 'react-router-dom';

import AppContext from '../../context/app/appContext';

import BackgroundImage from './BackgroundImage';
import JSONHelper from '../../helpers/JSON';
import AppHelper from '../../helpers/App';

const LocationItem = ({ json }) => {

    const appContext = useContext(AppContext);

    let trailsCount = json.groups.length;
    const trailsText = trailsCount > 1 ? 'trails' : 'trail'

    const groupsIds = AppHelper.getAdminIdsFromRefs(json, 'groups', 'group');
    const groups = AppHelper.getContentsByAdminIds(appContext, groupsIds, 'groups');

    let types = [];

    let typesWithCount = [];

    groups.forEach((g, index) => {

        let typeObj = typesWithCount.find(type => type.type == g.locationType);
        if (typeObj == undefined) {
            typesWithCount.push({ type: g.locationType, count: 1 });
        } else {
            typeObj.count++;
        }

    });

    let typesOutput = [];
    let connector = '';
    typesWithCount.forEach((type, index) => {
        if (typesWithCount.length == 2)
            if (index == 0) {
                connector = ' and ';
            } else {
                connector = '';
            }
        if (typesWithCount.length == 3) {
            if (index == 0) {
                connector = ', ';
            } else if (index == 1) {
                connector = ' and ';
            } else {
                connector = '';
            }
        }
        var singular = type.type, plural;

        if (singular == 'Trail with questions') {
            singular = 'trail';
            plural = 'trails';
        }

        if (singular == 'Quiz') {
            plural = type.type + 'zes';
        } else {
            plural = type.type + 's';
        }


        if (type.count > 1) {
            typesOutput.push(type.count + ' ' + plural + connector);
        } else {
            typesOutput.push(type.count + ' ' + singular + connector);
        }

    });


    useEffect(() => {

    }, []);


    return (
        <li>
            <NavLink to={json.siteUrl}>

                <BackgroundImage json={JSONHelper.getValue(json, 'multimedia', false)} />

                <div>
                    <em></em>
                    <strong><span>{JSONHelper.getSingleTextValue(json, 'title')}</span></strong>
                    <span>{typesOutput.join(' ')}</span>
                </div>
            </NavLink>
        </li>
    )
};
export default LocationItem;
