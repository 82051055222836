
import AppHelper from '../../helpers/App';
import config from '../../config.js';
// preview data 
import SitePreviewJson from '../../data/preview-site.json';
import GroupPreviewJson from '../../data/preview-group.json';
import WaypointPreviewJson from '../../data/preview-waypoint.json';


import {
    SET_TRANSITION,
    SET_LOADING,
    SET_SITES_AND_GROUPS,
    SET_WAYPOINTS,
    SET_CONTENTS,
    SET_GROUP_PROGRESS,
    SET_WAYPOINT_PROGRESS,
    SET_WAYPOINT_VISITED,
    RESET_WAYPOINT_VISITED,
    SET_SITE_CATEGORY
} from '../types';


export default (state, action) => {

    const url = window.location.pathname;
    const parts = url.substr(1).split('/');
    const isPreviewMode = config.isPreviewMode();
    const isSinglePreviewMode = config.isSinglePreviewMode();

    switch (action.type) {

        case SET_LOADING:
            return {
                ...state,
                loaded: false
            };

        case SET_TRANSITION:
            return {
                ...state,
                transition: action.payload
            };

        case SET_WAYPOINTS:

            let waypoints = { ...state.waypoints };
            var wpIdsToRemove = [];
            waypoints[action.payload.id] = []; // action.payload.data.hits.hits;

            // get sorts
            const groupJson = AppHelper.getContentByAdminId(state, action.payload.id, 'groups');
            const sorts = AppHelper.getSortFromRefs(groupJson, 'waypoints', 'waypoint');

            // add preview jsons
            if (isSinglePreviewMode) {
                // set corrent path and ID 

                if (parts[0] === 'waypoint-preview' && parts[1]) {
                    if (parts[1].indexOf('content-preview') === 0) {
                        const id = parts[1].substr(16);
                        WaypointPreviewJson._source.themes[0].contents[0].admin.id = id;
                        WaypointPreviewJson._source.themes[0].contents[0].contentUrl = parts[1];
                        action.payload.data.hits.hits.push(WaypointPreviewJson);
                    }
                }

            }


            for (var w in action.payload.data.hits.hits) {
                let content = action.payload.data.hits.hits[w];

                if (isPreviewMode) { // keep contents in reviewmode = true

                } else {
                    if (content._source.reviewmode === true) {
                        wpIdsToRemove.push(content._source.admin.id);
                        continue;
                    }
                }

                content._source.sort = sorts[content._source.admin.id];
                waypoints[action.payload.id].push(content._source);
            }

            // sort using sort
            waypoints[action.payload.id].sort((a, b) => (a.sort > b.sort) ? 1 : -1);

            // if not preview, fix sorts and refs
            if (!isPreviewMode) {
                let groups = [...state.groups];
                AppHelper.fixSorts(waypoints[action.payload.id]);
                // remove reference and fix sort in references
                var currentGroup = groups.filter(content => content.admin.id === action.payload.id)[0];
                currentGroup.waypoints = currentGroup.waypoints.filter(function (w) {
                    return wpIdsToRemove.indexOf(w.waypoint.admin.id) === -1; // only not found
                });
                // fix sort attribute
                AppHelper.fixSorts(currentGroup.waypoints);

                return {
                    ...state,
                    groups: groups,
                    waypoints: waypoints,
                    loaded: true
                };
            } else {
                return {
                    ...state,
                    waypoints: waypoints,
                    loaded: true
                };
            }



        case SET_CONTENTS:
            let contents = { ...state.contents };
            var cntIdsToRemove = [];
            contents[action.payload.id] = []; // action.payload.data.hits.hits;

            const { data, groupId } = action.payload.data;

            // get sorts
            const waypoointJson = AppHelper.getWaypointById(state, groupId, action.payload.id);
            const wpContents = AppHelper.getWaypointContents(waypoointJson);
            let contentSorts = {};
            if (wpContents) {
                wpContents.forEach((cnt, index) => {
                    contentSorts[cnt.admin.id] = cnt.sort;
                });
            }

            for (var c in data.hits.hits) {
                let content = data.hits.hits[c];

                if (isPreviewMode) { // keep contents in reviewmode = true

                } else {
                    if (content._source.reviewmode === true) {
                        cntIdsToRemove.push(content._source.admin.id);
                        continue;
                    }
                }

                content._source.sort = contentSorts[content._source.admin.id];
                contents[action.payload.id].push(content._source);
            }

            // sort using sort
            contents[action.payload.id].sort((a, b) => (a.sort > b.sort) ? 1 : -1);

            // fix sorts after removed items if not preview
            if (!isPreviewMode) {
                AppHelper.fixSorts(contents[action.payload.id]);

                let waypoints = { ...state.waypoints };
                // remove reference and fix sort in references
                var currentWaypoint = waypoints[groupId].filter(wp => wp.admin.id === action.payload.id)[0];

                for (var theme in currentWaypoint.themes) {
                    let t = currentWaypoint.themes[theme];
                    t.contents = t.contents.filter(function (cnt) {
                        return cntIdsToRemove.indexOf(cnt.admin.id) === -1; // only not found
                    });

                    AppHelper.fixSorts(t.contents);
                }

                return {
                    ...state,
                    waypoints: waypoints,
                    contents: contents,
                    loaded: true
                };

            } else {
                return {
                    ...state,
                    contents: contents,
                    loaded: true
                };
            }


        case SET_SITES_AND_GROUPS:


            var sites = [];
            var groups = [];
            var sitesInReview = [];
            var groupsInReview = [];

            var mixedContents = action.payload.hits.hits;

            // add preview jsons
            if (isSinglePreviewMode) {

                // set corrent path and ID   
                if (parts[0] !== 'waypoint-preview' && parts[0].indexOf('waypoint-preview') === 0) {
                    const id = parts[0].substr(17);
                    GroupPreviewJson._source.waypoints[0].waypoint.admin.id = id;
                    GroupPreviewJson._source.waypoints[0].waypointUrl = parts[0];
                }
                if (parts[0].indexOf('trail-preview') === 0) {
                    const id = parts[0].substr(14);
                    SitePreviewJson._source.groups[0].group.admin.id = id;
                    SitePreviewJson._source.groups[0].groupUrl = parts[0];
                }

                mixedContents.push(SitePreviewJson);
                mixedContents.push(GroupPreviewJson);
            }
            //console.log('! contents in review !');
            for (var cc in mixedContents) {
                let content = mixedContents[cc];

                if (isPreviewMode) { // keep contents in reviewmode = true

                } else {
                    if (content._source.reviewmode === true) {
                        //console.log(content._source.summary_title);

                        if (content._type === 'cb-site') {
                            sitesInReview.push(content._source);
                        } else if (content._type === 'cb-group') {
                            groupsInReview.push(content._source);
                        }

                        continue;
                    }
                }

                if (content._type === 'cb-site') {
                    sites.push(content._source);
                } else if (content._type === 'cb-group') {
                    groups.push(content._source);
                }
            }

            // fix sorts and relations (we need to  remove related objects in review and fix others sort)
            //console.log('isPreviewMode',isPreviewMode);
            if (!isPreviewMode) {
                // fix sites, remove groups relations if they are in review
                if (groupsInReview.length) {
                    var grIds = [];
                    for (var rinr in groupsInReview) {
                        let gr = groupsInReview[rinr];
                        grIds.push(gr.admin.id);
                    }

                    for (var st in sites) {
                        let s = sites[st];
                        s.groups = s.groups.filter(function (g) {
                            //console.log('g',g)
                            if (g.group != null) {
                                return grIds.indexOf(g.group.admin.id) === -1; // only not found
                            }
                            return null;
                        });
                        // fix sort attribute
                        AppHelper.fixSorts(s.groups);

                    }
                }
            }

            // sort using sort
            sites.sort((a, b) => (a.sort > b.sort) ? 1 : -1);
            groups.sort((a, b) => (a.sort > b.sort) ? 1 : -1);

            return {
                ...state,
                sites: sites,
                groups: groups,
                sitesInReview: sitesInReview,
                groupsInReview: groupsInReview,
                loaded: true
            };

        case SET_GROUP_PROGRESS:
            return {
                ...state,
                groupProgress: action.payload
            };

        case SET_WAYPOINT_PROGRESS:
            return {
                ...state,
                waypointProgress: action.payload
            };

        case SET_WAYPOINT_VISITED:
            // find waypoitn and set it as visited
            let waypointsCopy = { ...state.waypoints };
            let wp = waypointsCopy[action.payload.id].filter(wp => wp.admin.id === action.payload.data.admin.id);
            if (wp && wp[0]) {


                if (wp[0].visited) {
                    return state;
                } else {
                    wp[0].visited = true;
                    return {
                        ...state,
                        waypoints: waypointsCopy
                    };
                }
            }

        case RESET_WAYPOINT_VISITED:
            let waypointsCopy2 = { ...state.waypoints };

            if (!waypointsCopy2[action.payload]) {
                return state;
            }
            if (waypointsCopy2[action.payload][0].visited === false) {
                return state; // already reseted
            }
            waypointsCopy2[action.payload].forEach((w, index) => {
                w.visited = false;
            });
            return {
                ...state,
                waypoints: waypointsCopy2
            };

        case SET_SITE_CATEGORY:
            let cat = action.payload.data;
            let siteId = action.payload.id;
            let siteSelectedCategory = { ...state.siteSelectedCategory };
            siteSelectedCategory[siteId] = cat;
            console.log(siteSelectedCategory);
            return {
                ...state,
                siteSelectedCategory: siteSelectedCategory
            };

        default:
            return state;
    }
};