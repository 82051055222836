import React from 'react';
import { NavLink } from 'react-router-dom';

const SearchLink = (props) => {

  return (
    <article className="search-headline">
      <h2><NavLink to="/search">Search for a location</NavLink></h2>
    </article>
  )
};
export default SearchLink;
