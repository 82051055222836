import React from 'react';

import Layout from '../layout/Loading';


const Loading = (props) => {
  return (
    <Layout>
      <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
    </Layout>
  )
}
export default Loading; 
