import React, { useState, useEffect, useRef } from 'react';
import Sponsor from './Sponsor';

const ReadMore = ({ text, sponsors, onExpand, onCollapse,linebreaks }) => {
    //const showAll = text.length <= 150; // maybe longer text, this is a strange way of doing it as length is no indicator of what can be seen it's more about the space thats' available
    const [showMore, setShowMore] = useState(false);
    const [showAll, setShowAll] = useState(false);
    const wrapperRef = useRef(null);
    const innerRef = useRef(null);

    const showHideMore = (event) => {
        if (event.type === 'click' || event.key === 'Enter' || event.key === ' ') {
            if (showMore) {
                onCollapse();
            } else {
                onExpand();
            }
            setShowMore(!showMore)
        }
    }

    const checkIfHasMore = () => {
        if (innerRef.current.offsetHeight > wrapperRef.current.offsetHeight + 10) {
            setShowAll(false);
        } else {
            setShowAll(true);
        }
    };

    useEffect(() => {

        checkIfHasMore();

        window.addEventListener("resize", checkIfHasMore);
        return () => window.removeEventListener("resize", checkIfHasMore);

    }, []);

    const textWithLinebreaks = text.split('\n').map((item, key) => {return `<p key="${key}">${item}&nbsp;</p>`})

    return (
        <div className="read-more-container">
            <div ref={wrapperRef} className={`more ${(showMore || showAll) ? "visible" : ""}`}>
                {linebreaks && <div ref={innerRef} dangerouslySetInnerHTML={{ __html: textWithLinebreaks.join(' ') }} />}
                {!linebreaks && <div ref={innerRef} dangerouslySetInnerHTML={{ __html: text }} />}
            </div>

            {sponsors &&
                <div className={`sponsors ${(showMore || showAll) ? "visible" : ""}`}>
                    {sponsors.map((sponsor, key) => {
                        return <Sponsor key={key} json={sponsor} />
                    })}
                </div>
            }

            {!showAll &&
                <span className="read-more" tabIndex="0" onClick={(event) => showHideMore(event)} onKeyDown={(event) => showHideMore(event)} aria-label="Read more">
                    <span>{showMore ? 'Read less' : 'Read more'}</span>
                </span>
            }
        </div >
    )
}
export default ReadMore;
