import React, { useReducer, useEffect } from 'react';
import AppContext from './appContext';
import AppReducer from './appReducer';

import config from '../../config.js';
import ElasticSearch from '../../data/ElasticSearch.js';
import AppHelper from '../../helpers/App';

import {
    SET_TRANSITION,
    SET_LOADING,
    SET_SITES_AND_GROUPS,
    SET_WAYPOINTS,
    SET_CONTENTS,
    SET_GROUP_PROGRESS,
    SET_WAYPOINT_PROGRESS,
    SET_WAYPOINT_VISITED,
    RESET_WAYPOINT_VISITED,
    SET_SITE_CATEGORY
} from '../types';

const AppState = props => {

    //const localState = JSON.parse(localStorage.getItem("app_state"));

    const initialState = {
        transition: 'fade',
        loaded: false,
        sites: [],
        groups: [],
        waypoints: {},
        contents: {},
        sitesInReview: [],
        groupsInReview: [],
        waypointsInReview: {},
        contentsInReview: {},
        groupProgress: 0,
        waypointProgress: 0,
        siteSelectedCategory: {}
    }

    const [state, dispatch] = useReducer(AppReducer, initialState); //localState || 

    useEffect(() => {
        //localStorage.setItem("app_state", JSON.stringify(state));
    }, [state]);

    //  

    const storeDataWithExpiry = (key, value, ttl) => {
        const now = new Date()

        // `item` is an object which contains the original value
        // as well as the time when it's supposed to expire
        const item = {
            value: value,
            expiry: now.getTime() + ttl,
        }
        localStorage.setItem(key, JSON.stringify(item))
    }

    const getDataWithExpiry = (key) => {
        const itemStr = localStorage.getItem(key)
        // if the item doesn't exist, return null
        if (!itemStr) {
            return null
        }
        const item = JSON.parse(itemStr)
        const now = new Date()
        // compare the expiry time of the item with the current time
        if (now.getTime() > item.expiry) {
            // If the item is expired, delete the item from storage
            // and return null
            localStorage.removeItem(key)
            return null
        }
        return item.value
    }

    const getContentsInReview = async () => {

        setLoading();
        const query = `{
          "query":{
              "bool":{
                  "must":[
                    {
                        "terms":{
                            "type.base": ["cb-waypoint", "cb-content"]
                        }
                    },
                    {
                        "term":{
                            "reviewmode": true
                        }
                    }
                  ],
                  "must_not":[],
                  "should":[]
              }
          },
          "from":0,
          "size":1000,
          "sort":[],
          "aggs":{}
      }`;

        const r = await ElasticSearch(query);
    };

    const getSitesAndTrails = async () => {

        //if getDataWithExpiry than return, else get data and save using storeDataWithExpiry

        setLoading();

        //const res = await fetch(`${config.jsonsUrl}/sites_trails.json`);
        //const data = await res.json();

        const query = `{
          "query":{
              "bool":{
                  "must":[
                      {
                          "terms":{
                              "type.base": ["cb-group", "cb-site"]
                          }
                      }
                  ],
                  "must_not":[],
                  "should":[]
              }
          },
          "from":0,
          "size":500,
          "sort":[],
          "aggs":{}
      }`;

        const r = await ElasticSearch(query);

        dispatch({
            type: SET_SITES_AND_GROUPS,
            payload: r
        });
    };

    const getWaypoints = async (groupJson) => {
        const groupId = groupJson.admin.id;
        const waypointIds = AppHelper.getAdminIdsFromRefs(groupJson, 'waypoints', 'waypoint');

        if (!state.waypoints[groupId]) {// already in ?

            setLoading();
            //const res = await fetch(`${config.jsonsUrl}/waypoints.json`);
            //const data = await res.json();

            const query = `{
                "query":{ 
                "bool":{
                    "must":[
                        {
                        "terms":{
                            "admin.id": ["${waypointIds.join('","')}"]
                        }
                        }
                    ],
                    "must_not":[],
                    "should":[]
                } 
                },
                "from":0,
                "size":100,
                "sort":[],
                "aggs":{}
            }`;

            const data = await ElasticSearch(query);

            dispatch({
                type: SET_WAYPOINTS,
                payload: {
                    id: groupId,
                    data: data
                }
            });

        }
    };


    const getContents = async (waypointJson, groupId) => {
        const waypointId = waypointJson.admin.id;
        const contents = AppHelper.getWaypointContents(waypointJson);
        var contedntIds = [];
        contents.forEach((c, index) => {
            if (c.admin) {
                contedntIds.push(c.admin.id);
            }
        });

        if (!state.contents[waypointId]) {// already in ?

            setLoading();
            //const res = await fetch(`${config.jsonsUrl}/contents.json`);
            //const data = await res.json();

            const query = `{
                "query":{ 
                "bool":{
                    "must":[
                        {
                        "terms":{
                            "admin.id": ["${contedntIds.join('","')}"]
                        }
                        }
                    ],
                    "must_not":[],
                    "should":[]
                } 
                },
                "from":0,
                "size":100,
                "sort":[],
                "aggs":{}
            }`;

            const data = await ElasticSearch(query);

            dispatch({
                type: SET_CONTENTS,
                payload: {
                    id: waypointId,
                    data: { data: data, groupId: groupId }
                }
            });

        }
    };

    const findSitesAndTrails = async () => {

        setLoading();
        const res = await fetch(`${config.jsonsUrl}/sites_trails.json`);
        const data = await res.json();

        dispatch({
            type: SET_SITES_AND_GROUPS,
            payload: data
        });
    };

    // set loading
    const setLoading = () => dispatch({ type: SET_LOADING });

    // selected content tybe by site
    const selectCategory = (siteId, category) => {
        dispatch({
            type: SET_SITE_CATEGORY,
            payload: {
                id: siteId,
                data: category
            }
        });
    }

    const getCategory = (siteId) => {
        return state.siteSelectedCategory[siteId] ? state.siteSelectedCategory[siteId] : false;
    }



    // set loading

    // set transition
    const setFadeTransition = () => {
        dispatch({
            type: SET_TRANSITION,
            payload: 'fade'
        });
    };
    const setFadeTransitionWithDelay = () => {
        setTimeout(() => {
            dispatch({
                type: SET_TRANSITION,
                payload: 'fade'
            });
        }, 500);
    };

    const setSlideTransition = () => {
        dispatch({
            type: SET_TRANSITION,
            payload: 'slide'
        });
    };
    const setSlideTransitionWithDelay = () => {
        setTimeout(() => {
            setSlideTransition();
        }, 500);
    };

    const setTransition = transition => {
        dispatch({
            type: SET_TRANSITION,
            payload: transition
        });
    };

    const setGroupProcessWithDelay = progress => {
        setTimeout(() => {
            dispatch({
                type: SET_GROUP_PROGRESS,
                payload: progress
            });
        }, 500);
    };

    const setWaypointProcessWithDelay = progress => {
        setTimeout(() => {
            dispatch({
                type: SET_WAYPOINT_PROGRESS,
                payload: progress
            });
        }, 500);
    };

    const waypointVisited = (waypointJson, groupId) => {
        dispatch({
            type: SET_WAYPOINT_VISITED,
            payload: {
                id: groupId,
                data: waypointJson
            }
        });
    }

    const waypointsVisitedReset = (groupId) => {
        dispatch({
            type: RESET_WAYPOINT_VISITED,
            payload: groupId
        });
    }




    return <AppContext.Provider
        value={{
            loaded: state.loaded,
            getSitesAndTrails: getSitesAndTrails,
            getContentsInReview: getContentsInReview,
            sites: state.sites,
            groups: state.groups,
            getWaypoints: getWaypoints,
            waypoints: state.waypoints,
            getContents: getContents,
            contents: state.contents,

            sitesInReview: state.sitesInReview,
            groupsInReview: state.groupsInReview,
            waypointsInReview: state.waypointsInReview,
            contentsInReview: state.contentsInReview,

            transition: state.transition,
            setTransition: setTransition,
            setFadeTransition: setFadeTransition,
            setFadeTransitionWithDelay: setFadeTransitionWithDelay,
            setSlideTransition: setSlideTransition,
            setSlideTransitionWithDelay: setSlideTransitionWithDelay,
            setGroupProcessWithDelay: setGroupProcessWithDelay,
            setWaypointProcessWithDelay: setWaypointProcessWithDelay,
            groupProgress: state.groupProgress,
            waypointProgress: state.waypointProgress,
            waypointVisited: waypointVisited,
            waypointsVisitedReset: waypointsVisitedReset,
            siteSelectedContentType: state.siteSelectedContentType,
            selectCategory: selectCategory,
            getCategory: getCategory


        }}>
        {props.children}
    </AppContext.Provider>
};

export default AppState;