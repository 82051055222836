import React, { useEffect, useContext, lazy, Suspense } from 'react';

import Layout from '../layout/Modal';
import Head from './components/Head';

import AppContext from '../context/app/appContext';
import AppHelper from '../helpers/App';

//import MapMapBox from './components/MapMapBox';

import MapLocalImages from './components/MapLocalImages';




/*
if (process.env.NODE_ENV !== 'production') {
    var ReactDOM = require('react-dom');
    var axe = require('react-axe');
    axe(React, ReactDOM, 1000);
}
*/
const renderLoader = () => <div className="lds-ring"><div></div><div></div><div></div><div></div></div>;
const MapMapBox = lazy(() => import('./components/MapMapBox'))


const Map = ({ location, history }) => {
    const appContext = useContext(AppContext);

    const url = AppHelper.getFirstUrlFromLocation(location);
    const calledFromTrailHompage = AppHelper.getThirthUrlFromLocation(location);

    const groupJson = AppHelper.getGroupByUrl(appContext, url);
    const groupId = groupJson.admin.id;
    const mapType = groupJson.mapType;

    var backUrl = '/' + url + '/waypoints';
    if (calledFromTrailHompage === 'hp') {
        backUrl = '/' + url;
    }


    //console.log(groupJson);

    const escaping = (event) => {
        if (event.keyCode === 27) {
            // go back
            goBack();
        }
    }

    const goBack = (event) => {
        //appContext.setSlideTransitionWithDelay();
        history.push(backUrl);
    };

    useEffect(() => {

        appContext.getWaypoints(groupJson); // get or get already loaded

        window.addEventListener('keydown', escaping);
        //appContext.setSlideTransition(); 

        return () => {
            window.removeEventListener('keydown', escaping);
        }
    }, []);

    return (
        <Layout>
            <Head headline="Select a location to start" closeCallback={goBack} disableLogo={true} history={history} />

            <main className="map-page" role="main">
                <div id="map-view">
                    {mapType === 'mapbox' && <Suspense fallback={renderLoader()}><MapMapBox groupJson={groupJson} location={location} history={history} /></Suspense>}
                    {mapType !== 'mapbox' && <MapLocalImages groupJson={groupJson} location={location} history={history} />}
                </div>
            </main>

        </Layout>

    )
};
export default Map;
