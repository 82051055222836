import React, { useReducer } from 'react';
import ProgressContext from './progressContext';
import ProgressReducer from './progressReducer';

import {
    SET_GROUP_PROGRESS,
    SET_WAYPOINT_PROGRESS
} from '../types';

const ProgressState = props => {
    const initialState = {
        groupProgress: 0,
        waypointProgress: 0
    }

    const [state, dispatch] = useReducer(ProgressReducer, initialState);

    const setGroupProcessWithDelay = progress => {
        setTimeout(() => {
            dispatch({
                type: SET_GROUP_PROGRESS,
                payload: progress
            });
        }, 320);
    };

    const setWaypointProcessWithDelay = progress => {
        setTimeout(() => {
            dispatch({
                type: SET_WAYPOINT_PROGRESS,
                payload: progress
            });
        }, 320);
    };

    const setWaypointProcess = progress => {
        dispatch({
            type: SET_WAYPOINT_PROGRESS,
            payload: progress
        });
    };


    return <ProgressContext.Provider
        value={{
            setGroupProcessWithDelay: setGroupProcessWithDelay,
            setWaypointProcessWithDelay: setWaypointProcessWithDelay,
            setWaypointProcess: setWaypointProcess,
            groupProgress: state.groupProgress,
            waypointProgress: state.waypointProgress

        }}>
        {props.children}
    </ProgressContext.Provider>
};

export default ProgressState;