import {
    SET_GROUP_PROGRESS,
    SET_WAYPOINT_PROGRESS
} from '../types';

export default (state, action) => {
    switch (action.type) {
        case SET_GROUP_PROGRESS:
            return {
                ...state,
                groupProgress: action.payload
            };
        case SET_WAYPOINT_PROGRESS:
            return {
                ...state,
                waypointProgress: action.payload
            };
        default:
            return state;
    }
};