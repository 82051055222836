import React, { useState, useEffect, useContext } from 'react';

import Layout from '../layout/Modal';
import Head from './components/Head';

import AppContext from '../context/app/appContext';
import AppHelper from '../helpers/App';
import JSONHelper from '../helpers/JSON';

// content types 
import Content from './components/Content';

/*
if (process.env.NODE_ENV !== 'production') {
    var ReactDOM = require('react-dom');
    var axe = require('react-axe');
    axe(React, ReactDOM, 1000);
}
*/

const Gallery = ({ location, history }) => {

    const appContext = useContext(AppContext);

    const [waypointJson, setWaypointJson] = useState({});
    const [contentJson, setContentJson] = useState({});
    const [contentDataJson, setContentDataJson] = useState({});
    const [mediaJson, setMediaJson] = useState({});

    const waypintUrl = AppHelper.getFirstUrlFromLocation(location);
    const contentUrl = AppHelper.getSecondUrlFromLocation(location);

    const contentDetailUrl = '/' + waypintUrl + '/' + contentUrl;
    const mediaSortIdent = Number(AppHelper.getFourthUrlFromLocation(location));

    const groupJson = AppHelper.getGroupByWaypointUrl(appContext, waypintUrl);
    const waypointData = AppHelper.getWaypointDataFromGroupByUrl(waypintUrl, groupJson);
    const waypointId = waypointData.waypoint.admin.id;
    const groupId = groupJson.admin.id;


    const escaping = (event) => {
        if (event.keyCode === 27) {
            goBack();
        }
    }

    const goBack = (event) => {
        history.push(contentDetailUrl);
    };

    // set content data json
    if (Object.keys(waypointJson).length && !Object.keys(contentDataJson).length) {
        const contents = AppHelper.getWaypointContents(waypointJson);
        const content = AppHelper.getContentByUrl(contents, contentUrl);
        setContentDataJson(content);
    }

    // set content json
    if (appContext.contents[waypointId] && appContext.contents[waypointId].length && contentDataJson.admin && !Object.keys(contentJson).length) {
        let content = AppHelper.getContentById(appContext, waypointId, contentDataJson.admin.id);
        setContentJson(content);
        setMediaJson(AppHelper.getContentBySort(content.multimedia, mediaSortIdent))
    }

    const getDescription = () => {
        var title = JSONHelper.getMediaTitleByType(mediaJson, 'main title');
        if (!title.length) {
            title = JSONHelper.getMediaTitleByType(mediaJson, 'image alt');
        }

        return title.length ? (<p>{title}</p>) : '';
    };

    const getLegal = () => {
        var legal = '';
        if ('legal' in mediaJson.source) {
            legal = mediaJson.source.legal.credit_line;
        }
        return legal.length ? (<p className="legal">{legal}</p>) : '';
    };

    // get waypoints and contents
    useEffect(() => {

        appContext.getWaypoints(groupJson);

        window.addEventListener('keydown', escaping);
        return () => {
            window.removeEventListener('keydown', escaping);
        }
    }, []);

    // get waypoint detail  
    useEffect(() => {
        if (appContext.waypoints[groupId] && appContext.waypoints[groupId].length) {
            let json = AppHelper.getWaypointById(appContext, groupId, waypointId);
            setWaypointJson(json);
            appContext.getContents(json, groupId);

        }

    }, [appContext.waypoints]);


    return (
        <Layout>
            <Head closeUrl={contentDetailUrl} disableLogo={true} history={history} />

            {Object.keys(mediaJson).length && Object.keys(waypointJson).length &&
                <main className="gallery-page" role="main">

                    <div id="gallery-view">
                        <figure className="fwi">
                            {
                                <Content
                                    detail={true}
                                    type={mediaJson.mediaType ? mediaJson.mediaType : 'image'}
                                    contentJson={contentJson}
                                    media={mediaJson}
                                    click={false}
                                />
                            }

                        </figure>
                        <article>
                            {getDescription()}
                            {getLegal()}
                        </article>
                    </div>
                </main>
            }
        </Layout>
    )
};
export default Gallery;
