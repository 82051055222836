
import { useEffect } from 'react';

const ScrollToTop = (props) => {

    useEffect(() => {
        if (localStorage.getItem('disable_scroll') === "1") {
            localStorage.setItem('disable_scroll', "0")
        } else {
            window.scrollTo(0, 0);
        }

    }, [props]);

    return (props.children);
};
export default ScrollToTop;

