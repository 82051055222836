import React, { useContext } from 'react';

import AppContext from './context/app/appContext';

import AppHelper from './helpers/App';

import Site from './pages/Site';
import Trail from './pages/Trail';
import Waypoints from './pages/Waypoints';
import Directions from './pages/Directions';
import Content from './pages/Content';
import WaypointResult from './pages/WaypointResult';
import TrailResult from './pages/TrailResult';
import Map from './pages/Map';
import Gallery from './pages/Gallery';
import NotFound from './pages/NotFound';
import Exiting from './pages/Exiting';


function PageSwitch(props) {

    const appContext = useContext(AppContext);

    const pageTypes = {
        site: Site,
        group: Trail,
        notfound: NotFound,
        waypoints: Waypoints,
        map: Map,
        directions: Directions,
        content: Content,
        waypointResult: WaypointResult,
        trailResult: TrailResult,
        gallery: Gallery,
        exiting: Exiting
    };

    // is site ? 
    // is group ? 
    // is waypoint ?  
    // has trailing so content .. ?? 
    // otherwise not found
    const getPageTypeByUrl = (url) => {

        let parts = url.substr(1).split('/');

        var first = parts[0],
            second = parts[1],
            thirth = parts[2];

        // pages variations
        if (second === 'waypoints') { // waypoints screen of a group / trail 
            //appContext.getWaypoints();
            return pageTypes.waypoints;
        }
        if (second === 'map') { // map screen of a group / trail 
            return pageTypes.map;
        }
        // not used, we need direct url
        //if (second === 'directions') { // directions screen of a waypoint
        //    return pageTypes.directions;
        //}

        if (second === 'result') { // result screen of a waypoint
            return pageTypes.waypointResult;
        }

        if (second === 'results') { // result screen of a trail
            return pageTypes.trailResult;
        }

        if (thirth === 'gallery') { // galley screen of a content
            return pageTypes.gallery;
        }

        if (second === 'exit' || thirth === 'exit') { // exiting trail or waypoint
            return pageTypes.exiting;
        }

        if (parts.length == 1) { // site or trail / group

            if (AppHelper.getGroupByWaypointUrl(appContext, first)) { // waypoint 
                return pageTypes.directions;
            }
            if (AppHelper.getSiteByUrl(appContext, first)) { // site
                return pageTypes.site;
            }
            if (AppHelper.getGroupByUrl(appContext, first)) { //trail / group 
                return pageTypes.group;
            }

        } else if (parts.length == 2) { // content or question detail
            return pageTypes.content;
        }


        return pageTypes.notfound;
    }

    // find out what to show ?!?!
    const currentUrl = props.location.pathname;
    const PageToRender = getPageTypeByUrl(currentUrl);

    //console.log(appContext);

    return <PageToRender {...props} />;

}

export default (PageSwitch); 