import React from 'react';
import TextLink from './TextLink';

const BottomLinks = (props) => {

  return (
    <ul className="links footer">
      <li><TextLink text="More about the Mobile Guide" url="/about" /></li>
      <li><TextLink text="RAF Museum website" url="https://www.rafmuseum.org.uk/" target="_blank" rel="noopener" /></li>
      <li className="madeby"><TextLink text="Made by Open Cultu.re" url="https://opencultu.re/" target="_blank" rel="noopener noreferrer" /></li>
    </ul>
  )
};
export default BottomLinks;
