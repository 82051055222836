
import React from 'react';
import Image from './Image';

const FullwidthImage = ({ json }) => {

  var imgData = false;
  if (json && json[0]) {
    imgData = json[0];
  }

  return json ? (
    <figure className="fwi">
      <Image json={imgData} size="medium" />
    </figure>
  ) : '';
};
export default FullwidthImage;

