import React from 'react';
import { NavLink } from 'react-router-dom';
import ProgressBar from '../components/ProgressBar';


const Head = (props) => {

    const backText = props.backText ? props.backText : 'Back';

    const goingBack = (event, isBackButton) => {
        if (event.type === 'click' || event.key === 'Enter' || event.key === ' ') {
            localStorage.setItem("going_back", "1");

            if (isBackButton && props.backCallback) {
                props.backCallback(event);
            }
        }

    }

    const showConfirmation = (event) => {
        if (event.type === 'click' || event.key === 'Enter' || event.key === ' ') {
            localStorage.setItem('keep_playing', '1');
            //appContext.setTransition('fade'); 

            setTimeout(() => {
                props.history.push(props.exitUrl);
            }, 300);

        }
    }

    return (
        <div id="head" className={`${(props.modalOpened) ? "modal-opened" : ""}`} role="navigation">

            {props.headline && <strong className="headline">{props.headline}</strong>}

            {!props.headline && props.disableLogo !== true && !props.backUrl && <NavLink className="logo" to="/" onClick={(event) => goingBack(event)} onKeyDown={(event) => goingBack(event)} aria-label="Go Back" tabIndex="0">
                <span>Royal AirForce musem</span>
            </NavLink>
            }

            {props.showProgess && <ProgressBar ident="progress-overall" total={true} completed={props.completed} groupJson={props.groupJson} waypointJson={props.waypointJson} waypointData={props.waypointData} />}

            {props.closeCallback &&
                <a onClick={(event) => props.closeCallback(event)} onKeyDown={(event) => props.closeCallback(event)} className="close" aria-label="Close" tabIndex="0"></a>
            }

            {props.exitUrl &&
                <a onClick={(event) => showConfirmation(event)} onKeyDown={(event) => showConfirmation(event)} className="close" aria-label="Close" tabIndex="0"></a>
            }

            {(props.closeUrl) &&
                <NavLink to={props.closeUrl} onClick={(event) => goingBack(event)} onKeyDown={(event) => goingBack(event)} className="close" aria-label="Go Back" tabIndex="0"></NavLink>
            }
            {(props.backUrl) &&
                <NavLink to={props.backUrl} onClick={(event) => goingBack(event, true)} onKeyDown={(event) => goingBack(event, true)} className={`back ${backText ? 'has-text' : ''}`} aria-label="Go Back" tabIndex="0">
                    {backText}
                </NavLink>
            }

        </div>
    )
};
export default Head;
