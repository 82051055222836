import React, { useState } from 'react';

import Image from './Image';

import AppHelper from '../../helpers/App';

const MapLocalImages = ({ groupJson, location, history }) => {

    const [visibleMap, setVisibleMap] = useState(0);
    const [previousMap, setPreviousMap] = useState(false);
    const [currentActivePoint, setCurrentActivePoint] = useState(false);
    const [locationName, setLocationName] = useState('Full Map');

    const firstImg = groupJson.maps[0][0].mapImage.multimedia[0];
    var imageRatio = 56;
    if (firstImg) {
        let dimensions = firstImg.processed.medium.measurements.dimensions;
        imageRatio = dimensions[0].value / dimensions[1].value * 100;
    }


    // console.log(groupJson);

    const pointClick = (event, point, ident) => {
        if (event.type === 'click' || event.key === 'Enter' || event.key === ' ') {

            if (currentActivePoint === ident) { // already clicked
                if (point.type === 'point') { // go to waypoint
                    const waypointData = AppHelper.getWaypointDataFromGroup(point.waypoint, groupJson);
                    const url = '/' + waypointData.waypointUrl;
                    history.push(url)
                } else { // zoom
                    setPreviousMap(visibleMap);
                    setVisibleMap(point.zoomPoint);
                }
            } else {

            }

            setLocationName(point.label)
            setCurrentActivePoint(ident);
        }
    }

    const goBack = (event) => {
        if (event.type === 'click' || event.key === 'Enter' || event.key === ' ') {
            setVisibleMap(previousMap);
        }
    }

    return (
        <div id="map-local">
            <div className="maps-list" style={{ paddingBottom: imageRatio + '%' }}>

                {groupJson.maps.map((mapJson, i) => {

                    const mapImage = mapJson[0].mapImage.multimedia[0];
                    const mapMetaData = mapJson[1].mapMetaData;

                    //console.log(mapImage); console.log(mapMetaData);

                    return (
                        <div key={i} className={`map-list-item ${visibleMap === i ? 'active' : ''}`}>

                            <Image json={mapImage} size="large" />

                            {mapMetaData && <div className="map-locations">
                                {mapMetaData.map((point, z) => {
                                    const ident = i + '_' + z;
                                    return (
                                        <button
                                            onKeyDown={(event) => pointClick(event, point, ident)}
                                            onClick={(event) => pointClick(event, point, ident)}
                                            key={z}
                                            title={point.label}
                                            className={`location-pointer ${point.type} ${ident === currentActivePoint ? 'active' : ''}`}
                                            style={{ top: point.top + '%', left: point.left + '%' }}
                                        />
                                    );
                                })}
                            </div>
                            }
                        </div>
                    );
                })}

                <div className="location-name">
                    {locationName}
                </div>

                {previousMap !== false &&
                    <div className="back-link">
                        <button type="button"
                            onKeyDown={(event) => goBack(event)}
                            onClick={(event) => goBack(event)}
                        />
                    </div>
                }

            </div>
        </div>
    );

}
export default MapLocalImages;
