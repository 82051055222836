import React, { useState, useEffect, useRef } from 'react';

const Chart = ({ score }) => {

  const [result, setResult] = useState(0);
  var completed = 0;
  var timer = useRef(false);

  const tick = () => {
    completed++;
    setResult(completed);
    if (completed >= score) {
      clearInterval(timer.current);
    }
  }

  useEffect(() => {
    if (score) {
      setTimeout(() => {
        timer.current = setInterval(
          () => tick(),
          20
        );
      }, 500);
    }

    return () => {
      clearInterval(timer.current);
    }

  }, [score]);

  return (
    <figure className="chart-wrap">
      <div className={`ch ${'p' + result} ${result < 33 ? "bad" : ""} ${(result < 66 && result >= 33) ? "moderate" : ""} ${result >= 66 ? "good" : ""} `} >
        <span>{result}%</span>
        <div className="slice">
          <div className="bar"></div>
          <div className="fill"></div>
        </div>
      </div>
    </figure>
  )
};
export default Chart;


