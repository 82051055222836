import React, { useContext, useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import Layout from '../layout/WithHeader';
import Head from './components/Head';
import ProgressBar from './components/ProgressBar';
import Share from './components/Share';
import BackgroundImage from './components/BackgroundImage';
import Chart from './components/Chart';
import Results from './components/Results';

import AppContext from '../context/app/appContext';
import QuizContext from '../context/quiz/quizContext';

import AppHelper from '../helpers/App';
import JSONHelper from '../helpers/JSON';
import QuizHelper from '../helpers/Quiz';


/*
if (process.env.NODE_ENV !== 'production') {
    var ReactDOM = require('react-dom');
    var axe = require('react-axe');
    axe(React, ReactDOM, 1000);
}
*/

const TrailResult = ({ location, history }) => {

    const appContext = useContext(AppContext);
    const quizContext = useContext(QuizContext);

    const [showResultsByQuestion, setShowResultsByQuestion] = useState(false);

    const url = AppHelper.getFirstUrlFromLocation(location);
    const groupJson = AppHelper.getGroupByUrl(appContext, url);
    const groupDataJson = AppHelper.getGroupDataByUrl(appContext, url);
    const siteJson = AppHelper.getSiteByGroupUrl(appContext, url);
    const locationsUrl = '/' + siteJson.siteUrl;
    const locationType = groupJson.locationType.toLowerCase()
    const partOfSite = siteJson.locationType === 'Site' ? `the ${siteJson.summary_title} site` : siteJson.summary_title


    // has questions / amswers / is quiz ?
    var score = 0;
    const groupIdent = groupJson.admin.id;
    var isQuiz = false;

    if (quizContext.answers[groupIdent]) { //groupJson.locationType == "Quiz"
        isQuiz = true;
        score = QuizHelper.getTrailScore(quizContext, groupIdent);
    }

    const toggleResultsByQuestion = (event) => {
        if (event.type === 'click' || event.key === 'Enter' || event.key === ' ') {
            setShowResultsByQuestion(!showResultsByQuestion);
        }
    };

    const resetGroupProgress = () => {
        appContext.waypointsVisitedReset(groupIdent);
        quizContext.removeAnswers(groupIdent);
    };

    useEffect(() => {

        window.addEventListener('beforeunload', resetGroupProgress);

        return () => {
            resetGroupProgress();
            window.removeEventListener('beforeunload', resetGroupProgress);
        };

    }, []);


    return (
        <Layout>

            <Head closeUrl={locationsUrl} backUrl={false} history={history} showProgess={true} groupJson={groupJson} waypointJson={false} waypointData={false} completed={true} />

            <main className={`trail-result ${(groupDataJson.sort & 1) ? "odd" : "even"}`} role="main" aria-labelledby="headline">
                <article>
                    <h1 id="headline">{JSONHelper.getSingleTextValue(groupJson, 'title')}</h1>

                    <ProgressBar ident="progress-section" total={false} completed={true} />

                    <div className="text closer">
                        <h2>{`${JSONHelper.getSingleTextValue(groupJson, 'title')} complete`}</h2>

                        {
                            !isQuiz && <Share homepage={false} text="Share with your friends" title={JSONHelper.getSingleTextValue(groupJson, 'title')} />
                        }

                        {
                            JSONHelper.getValue(groupJson, 'shopLink', false) &&
                            <ul className="links">
                                <li><a href={JSONHelper.getValue(groupJson, 'shopLink', false)} target="_blank" rel="noopener noreferrer">Shop for related products</a></li>
                            </ul>
                        }
                    </div>
                </article>

                {
                    isQuiz &&
                    <div className="quiz-results">
                        <article>
                            <h4 className="headline">Congratulations! Here's your score:</h4>
                        </article>

                        <Chart score={score} />

                        <ul className="actions">
                            <li>
                                <Share text={true} homepage={false} completed={score} title={JSONHelper.getSingleTextValue(groupJson, 'title')} />
                            </li>
                            <li><a tabIndex="0" onClick={(event) => toggleResultsByQuestion(event)} onKeyDown={(event) => toggleResultsByQuestion(event)} className="safe-link"><span>{showResultsByQuestion ? 'Hide' : 'View'} results by question</span> </a></li>
                        </ul>

                        <div className={`results-by-question ${(showResultsByQuestion) ? "visible" : "hidden"}`}>
                            {<Results groupJson={groupJson} isVisible={showResultsByQuestion} />}
                        </div>
                    </div>
                }

                <article className="current-location">
                    <div className="text">
                        <h2>{`This ${locationType} is part of ${partOfSite}`}</h2>
                        <ul className="links" style={{ marginBottom: 20 }}>
                            <li><NavLink to={'/' + siteJson.siteUrl}>{`View all ${JSONHelper.getSingleTextValue(siteJson, 'title')} content`}</NavLink></li>
                        </ul>
                        <BackgroundImage json={JSONHelper.getValue(siteJson, 'multimedia', false)} />




                    </div>
                </article>

            </main>

            <footer className={`trail-result ${(groupDataJson.sort & 1) ? "odd" : "even"}`}>
                <article>
                    <figure className="logo"></figure>
                    <ul className="links">
                        <li><a href="/">Find other tours and quizzes</a></li>
                        <li><a href="https://www.rafmuseum.org.uk">More about the RAF Museum</a></li>
                    </ul>
                </article>
            </footer>
        </Layout>
    )
}
export default TrailResult;