import React, { useContext, useEffect, useRef } from 'react';

import Layout from '../layout/WithHeader';
import Head from './components/Head';
import Button from './components/Button';
import ProgressBar from './components/ProgressBar';

import QuizContext from '../context/quiz/quizContext';

import JSONHelper from '../helpers/JSON';
import Question from './components/Question';

const ContentQuestion = ({ contentJson, contentData, waypointJson, groupJson, waypointData, contentUrl, contents, nextPrevUrl, exitUrl, history }) => {

    const quizContext = useContext(QuizContext);
    var backListenerRef = useRef(false);
    const minimumAnswers = Number(JSONHelper.getValue(contentJson, 'minimumAnswers', '1'));
    const groupIdent = groupJson.admin.id;
    const waypointIdent = waypointJson.admin.id;
    const contentIdent = contentJson.admin.id;

    //console.log('history',history)
    /*
    const backListener = () => {
        backListenerRef.current = history.listen((location, action) => {
            console.log(location, action)
            if (action === "POP") {
                // browser back button !!! but also forward button !!!
                // we need nextPrevUrl.next for forward btn
                history.replace(nextPrevUrl.prev, nextPrevUrl);

            }
        });
    };

    useEffect(() => {
        backListener();
        return () => backListenerRef.current();
    }, []);
    */


    var anweredCount = 0;
    if (quizContext.answers[groupIdent] && quizContext.answers[groupIdent][waypointIdent] && quizContext.answers[groupIdent][waypointIdent][contentIdent]) {
        anweredCount = Object.keys(quizContext.answers[groupIdent][waypointIdent][contentIdent]).length;
    }

    const answerClicked = (answerIdent, isCorrect, isSelected) => {
        quizContext.anserQuestion({
            groupIdent: groupIdent,
            waypointIdent: waypointIdent,
            contentIdent: contentIdent,
            answerIdent: answerIdent,
            isCorrect: isCorrect,
            isSelected: isSelected,
            minimumAnswers: minimumAnswers
        });
    };

    return (
        <Layout>
            <Head exitUrl={exitUrl} backUrl={nextPrevUrl.prev} backText={nextPrevUrl.prevText} showProgess={true} history={history} groupJson={groupJson} waypointJson={waypointJson} waypointData={waypointData} completed={false} />

            <main className={`waypoint ${(waypointData.sort & 1) ? "odd" : "even"}`} role="main" aria-labelledby="headline">
                <h1 id="headline">{JSONHelper.getSingleTextValue(groupJson, 'title')}</h1>

                {contents.length > 1 &&
                    <ProgressBar ident="progress-section" total={false} groupJson={groupJson} waypointJson={waypointJson} waypointData={waypointData} contentData={contentData} completed={false} />
                }

                <Question
                    callback={answerClicked}
                    contentJson={contentJson}
                    waypointJson={waypointJson}
                    groupJson={groupJson}
                    minimumAnswers={minimumAnswers}
                    history={history}
                />

                <div className="bottom">
                    <Button to={nextPrevUrl.next} className={`${(anweredCount < minimumAnswers) ? "disabled" : ""}`} ariaLabel={'Continue'} text={'Next'} />
                </div>

            </main>
        </Layout>
    )
};
export default ContentQuestion;
