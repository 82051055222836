import React from 'react';
import { NavLink } from 'react-router-dom';
import BackgroundImage from './BackgroundImage';

import AppHelper from '../../helpers/App';
import JSONHelper from '../../helpers/JSON';

const TrailItem = ({ no, json: groupJson, site: siteJson }) => {

    const waypoints = groupJson.waypoints;
    //const locationType = groupJson.locationType;
    const durationShort = JSONHelper.getValue(groupJson, 'durationShort');

    // url = find url from site
    var url = '';
    if (siteJson) {
        url = AppHelper.getGroupUrlFromSite(groupJson.admin.id, siteJson);
    }

    return (
        <li>
            <NavLink to={url}>

                <BackgroundImage json={JSONHelper.getValue(groupJson, 'multimedia', false)} />

                <div>
                    <em>{no}</em>
                    <strong><span>{JSONHelper.getSingleTextValue(groupJson, 'title')}</span></strong>
                    <span>
                        {`${waypoints.length} location${waypoints.length > 1 ? 's' : ''}${durationShort ? ' / ' + durationShort : ''}`}<br />
                        {JSONHelper.getSingleTextValue(groupJson, 'highlightsText')}
                    </span>
                </div>
            </NavLink>
        </li >
    )
};
export default TrailItem;
