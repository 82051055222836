import {
    SET_ANSWER,
    REMOVE_ANSWERS
} from '../types';

export default (state, action) => {
    switch (action.type) {
        case REMOVE_ANSWERS:
            let answersCopy = { ...state.answers };
            if (answersCopy[action.payload.id]) {
                delete (answersCopy[action.payload.id]);
            }
            return {
                ...state,
                answers: answersCopy
            };

        case SET_ANSWER:

            const { groupIdent, waypointIdent, contentIdent, answerIdent, isCorrect, isSelected, minimumAnswers } = action.payload;


            let answers = { ...state.answers };
            // save to group / waypoint / content / no  
            if (!answers[groupIdent]) {
                answers[groupIdent] = {};
            }
            if (!answers[groupIdent][waypointIdent]) {
                answers[groupIdent][waypointIdent] = {};
            }
            if (!answers[groupIdent][waypointIdent][contentIdent]) {
                answers[groupIdent][waypointIdent][contentIdent] = {};
            }

            var selectedAnswersCount = 1 + Object.keys(answers[groupIdent][waypointIdent][contentIdent]).length;

            if (isSelected) {
                if (selectedAnswersCount > minimumAnswers) {
                    // remove prev one
                    var lastIdent = Object.keys(answers[groupIdent][waypointIdent][contentIdent]).pop();
                    delete (answers[groupIdent][waypointIdent][contentIdent][lastIdent]);
                }

                answers[groupIdent][waypointIdent][contentIdent][answerIdent] = {
                    isCorrect: isCorrect,
                    minimumAnswers: minimumAnswers
                };

            } else { // unselect
                delete (answers[groupIdent][waypointIdent][contentIdent][answerIdent]);
            }


            return {
                ...state,
                answers: answers
            };
        default:
            return state;
    }
};