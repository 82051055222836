import React, { useContext } from 'react';
import AppContext from '../context/app/appContext';
import Layout from '../layout/WithHeader';
import LocationItem from './components/LocationItem';
import SearchLink from './components/SearchLink';
import BottomLinks from './components/BottomLinks';


/*
if (process.env.NODE_ENV !== 'production') {
    var ReactDOM = require('react-dom');
    var axe = require('react-axe');
    axe(React, ReactDOM, 1000);
}
*/

const Home = (props) => {

    const appContext = useContext(AppContext);
    //appContext.setTransition('slide'); 
    return (
        <Layout>

            <span className="logo">
                <span>Royal AirForce musem</span>
            </span>

            <main className="homepage" role="main" aria-labelledby="headline">
                <article>
                    <strong className="headline">Mobile Guide</strong>

                    { /* list of type = site */}
                    <h1 id="headline">Trails and quizzes at one of our museums</h1>

                    <ul className="locations-list loc-ico">
                        {appContext.sites.filter(site => site.locationType === 'Site').map((json, i) => {
                            return (<LocationItem key={i} json={json} />)
                        })}
                    </ul>
                </article>

                { /* list of type = location */}
                <article>
                    <h2>Trails beyond the museum</h2>
                    <ul className="locations-list concept-ico">
                        {appContext.sites.filter(site => (site.locationType === 'Location' || site.locationType === 'Theme')).map((json, i) => {
                            return (<LocationItem key={i} json={json} />)
                        })}
                    </ul>
                </article>

                <SearchLink history={props.history} />

                <BottomLinks />

            </main>
        </Layout>
    )
}
export default Home;
