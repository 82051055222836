import React, { useState, useContext, useEffect } from 'react';

import ContentQuestion from './ContentQuestion';
import ContentContent from './ContentContent';

import AppContext from '../context/app/appContext';
import QuizContext from '../context/quiz/quizContext';
import AppHelper from '../helpers/App';
import JSONHelper from '../helpers/JSON';
/*
if (process.env.NODE_ENV !== 'production') {
    var ReactDOM = require('react-dom');
    var axe = require('react-axe');
    axe(React, ReactDOM, 1000);
}*/

const Content = ({ location, history }) => {

    const [resetVisitedOnBack, setResetVisitedOnBack] = useState(false);
    const [waypointJson, setWaypointJson] = useState({});
    const [contentJson, setContentJson] = useState({});
    const [contentDataJson, setContentDataJson] = useState({});
    const [nextPrevUrl, setNextPrevUrl] = useState({ prev: '', next: '', prevText: '' });
    const [waypointContents, setWaypointContents] = useState([]);

    const appContext = useContext(AppContext);
    const quizContext = useContext(QuizContext);

    const waypointUrl = AppHelper.getFirstUrlFromLocation(location);
    const contentUrl = AppHelper.getSecondUrlFromLocation(location);

    //if(singlePreview){} 
    const groupJson = AppHelper.getGroupByWaypointUrl(appContext, waypointUrl);
    const groupId = groupJson.admin.id;
    const groupUrl = '/' + AppHelper.getGroupUrlById(appContext, groupId);



    const waypointData = AppHelper.getWaypointDataFromGroupByUrl(waypointUrl, groupJson);
    const waypointId = waypointData.waypoint.admin.id;
    const nextWaypoint = AppHelper.getNextWaypoint(appContext, groupId, waypointData, groupJson.waypoints);
    const prevWaypoint = AppHelper.getPreviousWaypoint(appContext, groupId, waypointData, groupJson.waypoints);
    const waypointsUrl = groupUrl + '/waypoints';
    var directionsUrl = '/' + waypointUrl;

    const waypointHasDirectionsScreen = AppHelper.waypointHasDirections(groupJson, waypointData);

    if (!waypointHasDirectionsScreen) {
        directionsUrl = waypointsUrl;

        if (prevWaypoint) {
            directionsUrl = '/' + prevWaypoint.waypointUrl;
        }
    }



    const resultsUrl = '/' + waypointUrl + '/result';

    const exitUrl = '/' + waypointUrl + '/exit';


    const resetVisitedCallback = (event) => {
        // reset visited when going back, only if directions are empty, otherwise reset on directions back
        if (!waypointHasDirectionsScreen) {
            console.log('reseting');
            appContext.waypointsVisitedReset(groupId);
        }
    }

    // get next and prev contents, set content data json
    if (Object.keys(waypointJson).length && !Object.keys(contentDataJson).length) {

        const contents = AppHelper.getWaypointContents(waypointJson);
        const content = AppHelper.getContentByUrl(contents, contentUrl);
        setWaypointContents(contents);
        var prevText = '', nextText = 'More';

        const nextContent = AppHelper.getContentBySort(contents, content.sort + 1);
        const prevContent = AppHelper.getContentBySort(contents, content.sort - 1);
        const prevUrl = prevContent ? AppHelper.getContentUrl(waypointUrl, prevContent) : directionsUrl;
        var nextUrl = nextContent ? AppHelper.getContentUrl(waypointUrl, nextContent) : resultsUrl;

        if (!nextContent) { // last one, so visited 
            appContext.waypointVisited(waypointJson, groupId);
        }

        if (!prevContent) {  // prev is waypoint or directions
            if (!waypointHasDirectionsScreen) {
                prevText = AppHelper.getPreviousWaypointTitle(prevWaypoint);
                // reset when going back using browser button, only for contents, not quiz 
                if (groupJson.locationType !== "Quiz") {
                    setResetVisitedOnBack(true);
                }
            }

            //localStorage.setItem('run_back_callback', "1");
        } else {
            //localStorage.setItem('run_back_callback', "0");
        }

        if (!nextContent) {
            nextText = 'More'
            if (!nextWaypoint) {
                nextText = 'Finish'
            }
            if (contents.length <= 3) { // go to next waypoint or trail result  
                if (quizContext.answers[groupId] && quizContext.answers[groupId][waypointId]) {
                    // has questions, show results 
                } else { // no questions go to next waypoint or trail result 
                    const groupResultsUrl = groupUrl + '/results';
                    nextUrl = nextWaypoint ? '/' + nextWaypoint.waypointUrl : groupResultsUrl;
                    if (nextWaypoint) {
                        nextText = 'Next location';
                    }
                }
            }
        }

        // todo - can be trail result if just one waypoint

        setNextPrevUrl(
            {
                prev: prevUrl,
                next: nextUrl,
                prevText: prevText,
                nextText: nextText,
                directionsUrl: directionsUrl
            }
        );
        setContentDataJson(content);
    }

    // set content json
    if (appContext.contents[waypointId] && appContext.contents[waypointId].length && contentDataJson.admin && !Object.keys(contentJson).length && contentJson !== false) {
        setContentJson(AppHelper.getContentById(appContext, waypointId, contentDataJson.admin.id));
    }

    useEffect(() => {
        window.onpopstate = e => {
            if (resetVisitedOnBack) {
                appContext.waypointsVisitedReset(groupId);
            }
        }
        return () => {
            window.onpopstate = e => { };
        };
    }, [resetVisitedOnBack]);

    // get waypoints and contents
    useEffect(() => {
        appContext.getWaypoints(groupJson);

        window.onpopstate = e => {
            console.log(resetVisitedOnBack);
            console.log('reseting');
            appContext.waypointsVisitedReset(groupId);
        }

        return () => {
            window.onpopstate = e => { };
        };
    }, []);


    // get waypoint detail  
    useEffect(() => {
        if (appContext.waypoints[groupId] && appContext.waypoints[groupId].length) {
            let json = AppHelper.getWaypointById(appContext, groupId, waypointId);
            setWaypointJson(json);
            appContext.getContents(json, groupId);
        }

    }, [appContext.waypoints]);

    const renderSwitch = () => {

        switch (JSONHelper.getContentType(contentJson)) {
            case 'cb-content':
                return <ContentContent
                    contentJson={contentJson}
                    waypointJson={waypointJson}
                    groupJson={groupJson}
                    waypointData={waypointData}
                    contentData={contentDataJson}
                    contentUrl={contentUrl}
                    contents={waypointContents}
                    nextPrevUrl={nextPrevUrl}
                    exitUrl={exitUrl}
                    history={history}
                    backCallback={resetVisitedCallback}
                />;
            case 'cb-question':
                return <ContentQuestion
                    contentJson={contentJson}
                    waypointJson={waypointJson}
                    groupJson={groupJson}
                    waypointData={waypointData}
                    contentData={contentDataJson}
                    contentUrl={contentUrl}
                    contents={waypointContents}
                    nextPrevUrl={nextPrevUrl}
                    exitUrl={exitUrl}
                    history={history}
                />;
            default:
                return '';
        }
    };
    if (contentJson === false) { // not found
        return <div className="page"><div className="container" id="container"><h1>Not found</h1></div></div>;
    }
    return (
        Object.keys(contentJson).length && Object.keys(waypointJson).length && renderSwitch()
    )
}
export default Content;