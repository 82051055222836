import React, { useState } from 'react';
import JSONHelper from '../../helpers/JSON';
import QuestionOption from './QuestionOption';
import Image from './Image';

const Question = ({ callback, contentJson, waypointJson, groupJson, minimumAnswers, history, accordion }) => {

    const [visible, setVisible] = useState(false);

    const questionType = JSONHelper.getValue(contentJson, 'questionType', 'text');
    const questionTitle = JSONHelper.getSingleTextValue(contentJson, 'title');// props.questionsTypeText + ' ' + questionIdent;

    // eslint-disable-next-line
    const toggleQuestion = (event) => {
        if (accordion) {
            if (event.type === 'click' || event.key === 'Enter' || event.key === ' ') {
                setVisible(!visible);
            }
        }
    };

    const renderSwitch = (type) => {
        // eslint-disable-next-line
        const questionInfoText = JSONHelper.getSingleTextValue(contentJson, 'text');
        const optionsText = JSONHelper.getValue(contentJson, 'textOptions', []);
        const multimedia = JSONHelper.getValue(contentJson, 'multimedia', false);

        switch (type) {
            case 'text':
                return (
                    <ol className="options-text">
                        {optionsText.map((option, i) => {
                            return (<QuestionOption
                                key={i}
                                no={i + 1}
                                accordion={accordion}
                                callback={callback}
                                json={option}
                                type={type}
                                contentJson={contentJson}
                                waypointJson={waypointJson}
                                groupJson={groupJson}
                                minimumAnswers={minimumAnswers}
                            />)
                        })}
                    </ol>
                );
            case 'images':
                const optionsImages = JSONHelper.getValue(contentJson, 'imageOptions', []);
                return (
                    <ol className="options-images">
                        {optionsImages.map((option, i) => {
                            return (<QuestionOption
                                key={i}
                                no={i + 1}
                                accordion={accordion}
                                callback={callback}
                                json={option}
                                type={type}
                                contentJson={contentJson}
                                waypointJson={waypointJson}
                                groupJson={groupJson}
                                minimumAnswers={minimumAnswers}
                            />)
                        })}
                    </ol>
                );
            case 'mix':
                const primaryImage = JSONHelper.getPrimaryImage(multimedia);
                return (
                    <div className="mix">
                        <figure>
                            <Image json={primaryImage} size="medium" />
                        </figure>
                        <ol className="options-text">
                            {optionsText.map((option, i) => {
                                return (<QuestionOption
                                    key={i}
                                    no={i + 1}
                                    accordion={accordion}
                                    callback={callback}
                                    json={option}
                                    type="text"
                                    contentJson={contentJson}
                                    waypointJson={waypointJson}
                                    groupJson={groupJson}
                                    minimumAnswers={minimumAnswers}
                                />)
                            })}
                        </ol>
                    </div>
                );
            default:
                return '';
        }
    };

    return (
        <div className={`question ${visible ? "visible" : ""}`} onClick={(event) => toggleQuestion(event)} onKeyDown={(event) => toggleQuestion(event)}>
            <div className="res-padding">
                <h3 id="headline">
                    {questionTitle}
                </h3>
            </div>
            <div className="question-body">
                <p>{JSONHelper.getSingleTextValue(contentJson, 'text')}</p>

                <div className="options-wrap">
                    {renderSwitch(questionType)}
                </div>

            </div>
        </div>
    )
};
export default Question;
