import React, { useEffect, useRef, useContext } from 'react';

import AudioContext from '../../../context/audio/audioContext';

const AudioPlayer = () => {

    const audioContext = useContext(AudioContext);

    const audioEl = useRef(null);
    const sourceEl = useRef(null);

    useEffect(() => {
        audioContext.audioPlaying ? audioEl.current.play() : audioEl.current.pause();
    }, [audioContext.audioPlaying]);

    /*
    useEffect(() => {
        //setSource(audioContext.audioSrc); 
    }, [audioContext.audioSrc]);

    */

    useEffect(() => {
        //setSource(audioContext.audioSrc);

        if (audioContext.audioSrc && sourceEl.current) {
            if (audioEl.current) {
                audioEl.current.pause();
                audioEl.current.src = audioContext.audioSrc;
                audioEl.current.load();
                audioContext.playAudio(true);
            }

        } else {
            audioEl.current.pause();
            audioEl.current.src = "";
        }

    }, [audioContext.audioSrc]);

    const timeUpdate = () => {
        audioContext.setAudioProgress(
            Number(audioEl.current.currentTime / audioEl.current.duration * 100).toFixed(4)
        );
    };


    return (
        <audio ref={audioEl} onTimeUpdate={() => timeUpdate()} id="audio-player" className="audio-player" loop controls>
            <source ref={sourceEl} id="src_mp3" type="audio/mp3" src="" />
        </audio>
    );
};
export default AudioPlayer;