import React, { useContext, useState, useEffect } from 'react';

import AppContext from '../../context/app/appContext';
import QuizContext from '../../context/quiz/quizContext';

import ResultsByWaypoint from './ResultsByWaypoint';

const Results = ({ groupJson, isVisible }) => {

    const appContext = useContext(AppContext)
    const quizContext = useContext(QuizContext);
    const groupId = groupJson.admin.id;
    const waypoints = quizContext.answers[groupId] ? Object.keys(quizContext.answers[groupId]) : [];

    const [visible, setVisible] = useState(false);

    const toggleSection = (event, sectionIdent) => {
        if (event.type === 'click' || event.key === 'Enter' || event.key === ' ') {
            if (sectionIdent == visible) {
                sectionIdent = false;
            }
            setVisible(sectionIdent);
        }
    };
    // get waypoints 
    useEffect(() => {
        appContext.getWaypoints(groupJson)
    }, []);

    return (
        <main className="results-set">

            {quizContext.answers[groupId] && appContext.waypoints[groupId] &&
                waypoints.map((waypointIdent, i) => {
                    return <ResultsByWaypoint key={i} no={i + 1} isVisible={isVisible} groupJson={groupJson} groupId={groupId} waypointId={waypointIdent} visibleSection={visible} toggle={toggleSection} />
                })
            }


        </main>
    )
}
export default Results;
