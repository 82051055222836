import React from 'react';

import Layout from '../layout/WithHeader';
import Head from './components/Head';

import SearchLink from './components/SearchLink';
import BottomLinks from './components/BottomLinks';
/*
if (process.env.NODE_ENV !== 'production') {
    var ReactDOM = require('react-dom');
    var axe = require('react-axe');
    axe(React, ReactDOM, 1000);
}*/

const About = ({ location, history }) => {

    return (
        <Layout>

            <Head closeUrl={'/'} history={history} />


            <main className="text-page" role="main" aria-labelledby="headline">

                <article className="text">
                    <strong className="headline">Mobile Guide</strong>

                    <h1 id="headline">About the RAF Museum Mobile Guide</h1>
                    <p>The Vision of the RAF Museum is to inspire everyone with the RAF story – the people who shape it and its place in our lives. Our digital guide offers a range of RAF story-based tours and trails, from those focussed on our London and Cosford sites to all-day adventures beyond the Museum walls, such as the London Walking Trail.</p>
                    <h2>What is the RAF Museum Mobile Guide?</h2>
                    <p>The guide is designed to help Museum visitors explore our sites, collection and RAF Stories through a range of digital experiences. Initially funded by the National Lottery Heritage Fund, this guide is a part of our wider digital engagement programme to reach new audiences both on-site and online.</p>
                    <h2>What you can do with this Mobile Guide</h2>
                    <p>Each self-guided trail and tour shares significant episodes and ideas from the RAF Story - from the Battle of Britain to the use of biomimicry in aircraft development. These have been designed with specific audiences in mind, including trails and tours for schools, children and specialists.  The length ranges from one hour to all-day adventures and each includes clear directions.</p>
                    <p>Explore RAF Museum’s well-researched content, experience the interactive digital gallery, read guides, go on trails, take quizzes, and do it all in the Museum or anywhere. With this guide, you can access site trails, archive footage, stories, technological information, 360º views, multimedia content and much more.</p>
                    <p>The RAF Museum is constantly adding to the number of tours available, so please check back regularly.</p>   

                    <h2>How to use this Mobile Guide</h2>

                    <p>You can access the guide and all its content from anywhere. It’s best on mobile and tablet devices, but it also works on a desktop or laptop.</p>  

                    <p>If you are onsite at either our Cosford or London Museum, you can use the <a href="https://www.splashaccess.net/accounts/RAFMuseum/">RAF Museum’s public Wifi by clicking this link</a>. If you are doing one of the offsite trails, you will need to have a 3G/4G/5G data connection.
                    Navigate through the various trails and quizzes to learn more about the RAF, get acquainted with their stories, and be inspired by their rich history.</p>

                    <p>Looking for something specific? Type in a location or subject you want to know more about in the search bar.</p>

                    <h2>Why build a Mobile Guide?</h2>
                    <p>The RAF Museum’s Purpose is to share the story of the Royal Air Force, past, present and future – using the stories of its people and our collections in order to engage, inspire and encourage learning. The Museum is always looking for new methods to help us deliver this Purpose. Creating a wide-ranging set of tours and trails allows us to connect with different audiences, communities and partners, both on the Museum’s physical sites and beyond our boundaries - inspiring everyone with the RAF story.</p>

                    <h2>Design and Build</h2> 

                    <p>Commissioned by the Digital Team at the RAF Museum, the Mobile Guide was designed and built by <a href="https://opencultu.re">Open Cultu.re</a>, a digital design agency specialised in content focussed art and heritage projects.</p>

                </article>


                <SearchLink history={history} />

                <BottomLinks />

            </main>
        </Layout >
    )
}
export default About;