import React, { useState, useEffect, useRef, useContext, lazy, Suspense } from 'react';

import Layout from '../layout/WithHeader';
import Head from './components/Head';
import Textnl2p from './components/Textnl2p';
import Button from './components/Button';
import ProgressBar from './components/ProgressBar';

import JSONHelper from '../helpers/JSON';
import AudioContext from '../context/audio/audioContext';

const Gallery = lazy(() => import('./components/Gallery'))
const renderLoader = () => <div data-vjs-player className="content-video"><div className="video-container"><div className="lds-ring"><div></div><div></div><div></div><div></div></div></div></div>;


const ContentContent = ({ contentJson, contentData, waypointJson, groupJson, waypointData, contentUrl, contents, nextPrevUrl, exitUrl, history, backCallback }) => {

    const audioContext = useContext(AudioContext);
    const [showAudioInfo, setShowAudioInfo] = useState(false);

    // todo, how to stop this  
    useEffect(() => {
        //stopAudio(); 
        return () => {
            if (localStorage.getItem('keep_playing') !== '1') {
                stopAudio();
            } else {
                localStorage.setItem('keep_playing', '0');
            }
        };
    }, []);

    // audio 
    const audioEl = useRef(null);
    const audioContent = JSONHelper.getMediaByMediaType(contentJson.multimedia, 'audio');

    const stopAudio = () => {
        audioContext.playAudio(false);
        audioContext.setAudioSource('');
        audioContext.setAudioProgress(0);
    };

    const manageAudioButton = (event) => {
        if (event.type === 'click' || event.key === 'Enter' || event.key === ' ') {

            //set audio 
            if (!audioContext.audioPlaying) {
                if (audioContext.audioSrc !== audioContent.source.location) {
                    audioContext.setAudioSource(audioContent.source.location); // and play
                } else {
                    audioContext.playAudio(true);
                }

            } else {
                audioContext.playAudio(false);
            }



        }
    }

    const manageAudioIcom = (event) => {
        if (event.type === 'click' || event.key === 'Enter' || event.key === ' ') {
            setShowAudioInfo(!showAudioInfo);
        }
    }

    return (
        <Layout>

            <Head exitUrl={exitUrl} backUrl={nextPrevUrl.prev} backCallback={backCallback} backText={nextPrevUrl.prevText} showProgess={true} history={history} groupJson={groupJson} waypointJson={waypointJson} waypointData={waypointData} completed={false} />

            <main className={`waypoint ${(waypointData.sort & 1) ? "odd" : "even"}`} role="main" aria-labelledby="headline">
                <article>
                    <h1 id="headline">{JSONHelper.getSingleTextValue(contentJson, 'title')}</h1>

                    {contents.length > 1 &&
                        <ProgressBar ident="progress-section" total={false} groupJson={groupJson} waypointJson={waypointJson} waypointData={waypointData} contentData={contentData} completed={false} />
                    }

                    <Suspense fallback={renderLoader()}><Gallery contentJson={contentJson} waypointJson={waypointJson} groupJson={groupJson} contentUrl={contentUrl} history={history} /></Suspense>

                    {audioContent && audioContent.source &&
                        <div className="audio-controls">
                            <button tabIndex="0" id="audio-icon" aria-label="Show audio details" className={`audio-icon ${showAudioInfo ? "clicked" : ""}`} onKeyDown={(event) => manageAudioIcom(event)} onClick={(event) => manageAudioIcom(event)} />
                            <button tabIndex="0" ref={audioEl} id="audio-button" aria-label="Play / pause audio" className={`audio-button ${audioContext.audioPlaying ? "pause" : "play"}`} onKeyDown={(event) => manageAudioButton(event)} onClick={(event) => manageAudioButton(event)} />
                        </div>
                    }

                    {audioContent && audioContent.source &&
                        <div className={`audio-info ${showAudioInfo ? "visible" : "hidden"}`}>

                            <div id="progress-audio" className="progress-bar progress-audio"><div style={{ width: audioContext.audioProgress + "%" }}></div></div>

                            <p>{JSONHelper.getSingleTextValue(audioContent.source, 'title')}</p>
                        </div>
                    }

                    <div className="text bellow-gallery">
                        {
                            JSONHelper.getSingleTextValue(contentJson, 'subtitle') &&
                            <h2 className="headline">{JSONHelper.getSingleTextValue(contentJson, 'subtitle')}</h2>
                        }

                        <Textnl2p text={JSONHelper.getSingleTextValue(contentJson, 'text')} />

                        {
                            JSONHelper.getValue(contentJson, 'shopLink', false) &&
                            <ul className="links">
                                <li><a href={JSONHelper.getValue(contentJson, 'shopLink', false)} target="_blank">Shop for related products</a></li>
                            </ul>
                        }
                    </div>
                </article>

                <div className="bottom">
                    <Button to={nextPrevUrl.next} className={''} ariaLabel={'Continue'} text={nextPrevUrl.nextText} />
                </div>

            </main>
        </Layout>
    )
}
export default ContentContent;