
import React from 'react';
import JSONHelper from '../../helpers/JSON';
import Image from './Image';

const BackgroundImage = ({ json, clickFn, height, classAdd }) => {

    height = height ? height + "px" : false;

    var imgData = false;
    var imgUrl = '';
    // get primary ?
    if (json && json[0]) {
        imgData = JSONHelper.getPrimaryImage(json);
        imgData = imgData ? imgData : json[0];
    }

    if (imgData) {
        imgUrl = JSONHelper.getImageUrl(imgData, "medium");
    }

    if (!classAdd) {
        classAdd = '';
    }

    return json ? (
        <figure className={`bgi ${classAdd}`} style={{ backgroundImage: "url(" + imgUrl + ")", height: height }} onClick={(event) => clickFn ? clickFn(event) : false} onKeyDown={(event) => clickFn ? clickFn(event) : false}>
            <Image json={imgData} size="medium" />
        </figure>
    ) : '';
};
export default BackgroundImage;

